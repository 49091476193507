import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import useMediaQuery from '@mui/material/useMediaQuery';
import FameGlobalButton from 'components/buttons/FameGlobalButton';
import HomeButton from 'components/buttons/HomeButton';
import CustomTextfield from 'components/textfields/CustomTextfield';
import PasswordTextField from 'components/textfields/PasswordTextField';
import CurrentUserContext from 'hooks/currentUserContext';
import profileBg from '../../assets/profile/profileBg.jpg';
import profileDefault from '../../assets/profile/profileDefaultIcon.png';
import fameLogo from '../../assets/demo/images/logo.png';

function LoginPage() {
  const form = useRef();
  const navigate = useNavigate();

  const { id, setCurrentUser, ...currentUser }: any =
    useContext(CurrentUserContext);

  const matches = useMediaQuery('(min-width:600px)');

  const [signupError, setSignupError] = useState(false);

  const [inputValues, setInputValues] = useState({
    email: '',
    password: ''
  });

  const handleChange = (key: string) => (value: unknown) => {
    setInputValues({
      ...inputValues,
      [key]: value
    });
  };

  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');

  const login = async () => {
    const response = await fetch('https://fame.cool/signin', {
      method: 'POST',
      body: JSON.stringify({
        email: inputValues.email,
        password: inputValues.password
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });

    return response.json();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    login().then((data) => {
      if (data.token) {
        setCurrentUser({
          ...currentUser,
          id: data.id
        });

        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('fameConnect', data.token);
        localStorage.setItem('fameStar', data.id);
        navigate('/profile');
      }
      if (data.message === 'incorrect password') {
        setLoginError(true);
        setLoginErrorMsg('Password is incorrect.');
      } else if (data.message === 'not registered') {
        setLoginError(true);
        setLoginErrorMsg('Email is not registered.');
      }
    });
  };

  const handleGoogleLogin = async (credential: string) => {
    const response = await fetch('https://fame.cool/google-login', {
      method: 'POST',
      body: JSON.stringify({
        credential: credential
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return response.json();
  };

  const SocialsSignUpButton = (props: {
    color: string;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    startIcon: React.ReactNode;
    title: string;
  }) => {
    return (
      <Button
        variant='contained'
        startIcon={props.startIcon}
        sx={{
          width: matches ? '350px' : '250px',
          textTransform: 'none',
          backgroundColor: props.color,
          '&:hover': {
            backgroundColor: '#fff',
            color: '#FF0000'
          }
        }}
        onClick={props.onClick}
      >
        {props.title}
      </Button>
    );
  };

  return (
    <>
      <HomeButton page='landing-pages' />
      <Box
        sx={{
          backgroundImage: `url(${profileBg})`,
          height: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            width: matches ? '35%' : '100%',
            height: '100vh',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              padding: matches ? '30px 50px' : '50px 30px'
            }}
            spacing={2}
          >
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <img
                alt='fame-logo'
                src={fameLogo}
                style={{
                  width: '20%'
                }}
              />
              <Typography
                color='white'
                fontSize={matches ? '32px' : '24px'}
                fontWeight={800}
              >
                Profile
              </Typography>
            </Stack>
            <Typography fontSize='18px' fontWeight={600} color='white'>
              Log in
            </Typography>
            <div id='textfields' style={{ width: '90%' }}>
              <form ref={form as any} onSubmit={handleSubmit}>
                <CustomTextfield
                  hasError={false}
                  placeholder='Enter your email'
                  type='text'
                  label='Email Address'
                  value={inputValues.email}
                  onChange={handleChange('email')}
                />
                <PasswordTextField
                  label='Password'
                  value={inputValues.password}
                  onChange={handleChange('password')}
                  // inputProps={{
                  //   pattern:
                  //     '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{0,}$',
                  //   minLength: 8,
                  //   'data-testid': 'password',
                  // }}
                />
                <div
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}
                >
                  <FameGlobalButton
                    title='Log in'
                    color='red'
                    hoverColor='white'
                    onClick={() => {
                      return;
                    }}
                  />
                </div>
                {loginError && (
                  <Typography
                    fontSize={matches ? '14px' : '11px'}
                    color='red'
                    style={{ margin: '10px 0px 10px 0px' }}
                    textAlign='center'
                  >
                    {loginErrorMsg}
                  </Typography>
                )}
              </form>
            </div>

            <Typography
              fontSize='14px'
              color='white'
              style={{ margin: '10px 0px 10px 0px' }}
            >
              or
            </Typography>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleGoogleLogin(credentialResponse?.credential || '').then(
                  (data) => {
                    if (data.token) {
                      setCurrentUser({
                        ...currentUser,
                        id: data.id
                      });

                      localStorage.setItem('isLoggedIn', 'true');
                      localStorage.setItem('fameConnect', data.token);
                      localStorage.setItem('fameStar', data.id);
                      navigate('/profile');
                    }
                  }
                );
              }}
              onError={() => {
                setSignupError(true);
              }}
              // width={matches ? '350' : '150'}
            />
            {/* <SocialsSignUpButton
              color='#DB4437'
              title='Log in with Google'
              onClick={() => {
                console.log('hello');
              }}
              startIcon={<GoogleIcon />}
            /> */}
            {/* <SocialsSignUpButton
              color='#4267B2'
              title='Log in with Facebook'
              onClick={() => {
                console.log('hello');
              }}
              startIcon={<FacebookIcon />}
            />
            <SocialsSignUpButton
              color='#000000'
              title='Continue with Tiktok'
              onClick={() => {
                console.log('hello');
              }}
              startIcon={
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-tiktok'
                    viewBox='0 0 16 16'
                  >
                    {' '}
                    <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />{' '}
                  </svg>
                </>
              }
            /> */}
            <Typography
              color='white'
              fontSize='11px'
              paddingTop='2rem'
              textAlign='center'
            >
              Don&apos;t have an account yet? Sign up{' '}
              <span>
                <Link to='/create-profile' className='signup-link'>
                  here
                </Link>
              </span>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default LoginPage;
