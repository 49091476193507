import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";

function DanceAgainModal(props: any) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(props.open);

  const stopCamera = () => {
    if (window.localStream !== undefined) {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
    return;
  };

  const backtoHome = () => {
    stopCamera();
    navigate("/demo");
  };
  const danceAgain = () => {
    backtoHome();
  };

  const handleClose = () => {
    setOpen(false);
    backtoHome();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            backgroundColor: "white",
            border: "solid 3px red",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography align="center" fontWeight={600} fontSize={18}>
              Would You Like to Dance Again?
            </Typography>
          </DialogTitle>
          <DialogActions
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack direction="column" spacing={3}>
              <Stack direction="row" spacing={1}>
                <Button
                  size="large"
                  sx={{ color: "black", textTransform: "none" }}
                  onClick={handleClose} // to add onclick
                >
                  No
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    bgcolor: "#FF0000",
                    borderRadius: 5,
                    textTransform: "none",
                    ":hover": {
                      bgcolor: "#FF0000",
                    },
                  }}
                  onClick={danceAgain} // to add onclick
                >
                  Yes
                </Button>
              </Stack>
              <Divider variant="fullWidth" />
              <Stack direction={'column'} spacing={1} alignContent={"center"} alignItems={"center"} textAlign={"center"} >
                <Typography align="center" fontSize={14}>
                  Let us know your thoughts
                </Typography>
                <a
                  href="https://forms.gle/DQXq46aKEoDj6wfj9"
                  target="_blank"
                  style={{ textDecoration: "none", fontSize: "14px"}}
                  rel="noreferrer"
                >
                  Go to Feedback Form
                </a>
              </Stack>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default DanceAgainModal;
