import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CompetitionCard from '../components/demo/CompetitionCard';

function HomePage() {
  const matches = useMediaQuery('(min-width:600px)');
  const ref = useRef(null);

  const [showLoadMoreArrow, setShowLoadMoreArrow] = useState(false);

  function onScroll() {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight }: any = ref.current;
      setShowLoadMoreArrow(!(scrollTop + clientHeight === scrollHeight));
    }
  }

  const [competitionsData, setCompetitionsData] = useState([]);

  if (
    (performance.getEntriesByType('navigation')[0] as any).type === 'reload'
  ) {
    window.location.href = '/';
    console.info('This page is reloaded');
  }

  useEffect(() => {
    fetch('https://fame.cool/competitions')
      .then((response) => response.json())
      .then((data) => setCompetitionsData(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Box>
      <Box
        top={matches ? '147px' : '0.5vh'}
        minHeight='50px'
        position='absolute'
        right='0px'
        left='0px'
        bgcolor='black'
        zIndex={-1}
      >
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Box
            display='flex'
            px='5%'
            pt={matches ? '140px' : '190px'}
            width='800px'
          >
            <Typography color='#D9D9D9' fontSize='14px' fontWeight='600'>
              Competitions
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        ref={ref}
        onScroll={onScroll}
        overflow='auto'
        top={matches ? '300px' : '200px'}
        bottom={matches ? '60px' : '50px'}
        left='0px'
        right='0px'
        position='absolute'
        bgcolor='black'
        padding={matches ? '10px' : '5px'}
        zIndex={-2}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignContent='center'
          alignItems='center'
          px='5%'
        >
          <Grid
            container
            alignContent='center'
            alignItems='flex-start'
            maxWidth='900px'
          >
            {competitionsData.map(
              ({
                id,
                competitionCoverPhoto,
                hostProfilePhoto,
                competitionName,
                hostUsername,
                fameTokens,
                fameXP
              }) => (
                <Grid key={id} item xs={12} sm={12} md={6}>
                  <CompetitionCard
                    id={id}
                    competitionCoverPhoto={competitionCoverPhoto}
                    hostProfilePhoto={hostProfilePhoto}
                    competitionName={competitionName}
                    hostUsername={hostUsername}
                    competitionFameTokens={fameTokens}
                    fameXP={fameXP}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
      {showLoadMoreArrow ? (
        <Box position='fixed' bottom='70px' zIndex={10} left='0px' right='0px'>
          <Box display='flex' justifyContent='center'>
            <KeyboardArrowDownIcon sx={{ color: '#770000', fontSize: 50 }} />
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default HomePage;
