import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography, Backdrop, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LoadingScreen(props: { loading: boolean, screenMessage: string, isSlowConnection: boolean, isOpen: boolean }) {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const subTextMessage = props.isSlowConnection
    ? "Slow or no internet connection. Please check your internet settings"
    : " Please refresh your page if this screen appears for more than a minute";

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        opacity: 1,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
      }}
      open={props.isOpen}
    >
      <div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <BounceLoader
            color={"red"}
            loading={props.loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <Typography
            color="#D9D9D9"
            fontSize={matches ? "32px" : "28px"}
            fontWeight="600"
            sx={{
              mt: "30px",
              textShadow: "2px 2px 2px black",
              paddingX: "10px",
            }}
            align="center"
          >
            {props.screenMessage}
          </Typography>
          <Typography
            color="#D9D9D9"
            fontSize="16px"
            sx={{
              mt: "10px",
              marginX: "30px",
              textShadow: "2px 2px 2px black",
            }}
            align="center"
          >
            {subTextMessage}
          </Typography>

          {props.isSlowConnection ? (
            <Button
              size="medium"
              sx={{
                color: "black",
                textTransform: "none",
                bgcolor: "white",
                marginTop: "10px",
              }}
              onClick={() => navigate("/")}
            >
              Refresh
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </div>
    </Backdrop>
  );
}

export default LoadingScreen;
