import React, { useState, useContext } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ImageButton from 'components/buttons/ImageButton';
import ComingSoonDiv from 'components/fameClubPage/ComingSoonDiv';
import FameClubSignUpConfirmationModal from 'components/modals/FameClubSignUpConfirmation';
import signUpButtonUp from '../assets/fame-club/signUpButtonUp.png';
import signUpButtonDown from '../assets/fame-club/signUpButtonDown.png';
import CurrentUserContext from '../hooks/currentUserContext';

const details = [
  {
    key: 0,
    title: 'Post & Share Videos',
    videoLink:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/fame-club%2FFame-Club-Dancers-Animation_01.mp4',
    details: "Get 'Fame Famous' & Earn Money"
  },
  {
    key: 1,
    title: 'Earn Fame Stars for posting, sharing, & commenting',
    videoLink:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/fame-club%2FPos-_Share-Comment-Animation_01.mp4',
    details: 'Fame Stars increase your Fame Score & earn you money & prizes!'
  },
  {
    key: 2,
    title: 'Compete in Fame Club Challenges to win prizes and Stars',
    videoLink:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/fame-club%2FChallenges-Animation_01.mp4',
    details: 'Create your own Challenges for the community and earn rewards!'
  }
];

function ComingSoonPage() {
  const matches = useMediaQuery('(min-width:600px)');


  const [registeredEmail, setRegisteredEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const [emailTaken, setEmailTaken] = useState(false);
  const [openConfirmMsg, setOpenConfirmMsg] = useState(false);

  const handleClose = (event: any) => {
    event.preventDefault();
    event.target.value = ""
    setRegisteredEmail('');
    setOpenConfirmMsg(!openConfirmMsg);
  };

  const addEmail = async () => {
    const response = await fetch('https://fame.cool/waitlist', {
      method: 'POST',
      body: JSON.stringify({
        email: registeredEmail
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })

    if (response.status === 200) {
      setOpenConfirmMsg(true)
      setEmailTaken(false);
    } else if (response.status === 400) setEmailTaken(true);

    return response.json()
  };

  const { setCurrentUser, ...currentUser }: any =
    useContext(CurrentUserContext);

  const handleSignUp = (event: any) => {
    event.preventDefault();

    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (re.test(registeredEmail)) {
      setHasError(false);
      setCurrentUser({
        ...currentUser,
        email: registeredEmail
      });
      setRegisteredEmail('');
      addEmail();
    } else {
      setHasError(true);
    }
  };

  return (
    <Box
      top={matches ? '40vh' : '25vh'}
      // width="80%"
      minHeight='50px'
      position='absolute'
      right='0px'
      left='0px'
      zIndex={-1}
    >
      <div style={{ backgroundColor: 'red', paddingBottom: '3rem' }}>
        <Stack direction='column' justifyContent='center' alignItems='center'>
          <Typography
            color='white'
            fontSize={matches ? '40px' : '28px'}
            letterSpacing='-1px'
            fontWeight={800}
            textAlign="center"
            sx={{ textShadow: '1px 1px 1px 	#36454F', paddingTop: '3rem' }}
          >
            Skip the line at Fame Club
          </Typography>
          <Typography
            color='white'
            fontSize={matches ? '28px' : '20px'}
            letterSpacing='-1px'
            fontWeight={700}
            sx={{ textShadow: '1px 1px 1px 	#36454F' }}
          >
            Sign up for the waitlist
          </Typography>
        </Stack>

        <Stack
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          paddingTop='1rem'
          spacing={2}
        >
          <TextField
            error={hasError}
            value={registeredEmail}
            placeholder='Enter your email'
            onChange={(event) => setRegisteredEmail(event.target.value)}
            sx={{
              paddingTop: '5px',
              width: matches ? '30%' : '50%',
              input: {
                background: 'white'
              }
            }}
            FormHelperTextProps={{ style: { color: 'white' } }}
            helperText={hasError ? 'Please use a valid email address' : ''}
            size='small'
            required
          />
          <ImageButton
            width='111px'
            height='50px'
            src={signUpButtonUp}
            mouseOver={signUpButtonDown}
            onClick={handleSignUp}
          />
        </Stack>
        {emailTaken && (
          <Typography
            fontSize={matches ? '14px' : "11px"}
            color='white'
            style={{ margin: '10px 0px 10px 0px' }}
            textAlign='center'
          >
            Email already registered.
          </Typography>
        )}
      </div>


      <Box paddingX='12vw'>
        <Typography
          color='white'
          fontSize={matches ? '40px' : '28px'}
          letterSpacing='-1px'
          fontWeight={800}
          sx={{
            textShadow: '2px 2px 2px black',
            paddingY: matches ? '3rem' : '1rem',
            paddingX: matches ? 0 : '10vw',
            textAlign: 'center'
          }}
        >
          Social Media that Makes You Money
        </Typography>
        <Grid
          container
          spacing={{ xs: 2 }}
          columns={{ xs: 12, md: 12 }}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          {details.map((detail) => {
            return (
              <Grid item key={detail.key} xs={12} md={4}>
                <ComingSoonDiv
                  title={detail.title}
                  vidSrc={detail.videoLink}
                  details={detail.details}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <FameClubSignUpConfirmationModal
        isOpen={openConfirmMsg}
        handleClose={handleClose}
      />
      <div style={{ height: '50px' }} />
    </Box>
  );
}

export default ComingSoonPage;
