import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Link, Paper, Stack, Snackbar, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import discord from "../../../assets/landing-pages/discordLogo.png";
import twitter from "../../../assets/landing-pages/twitterLogo.png";
import youtube from "../../../assets/landing-pages/youtubeLogo.png";
import ContactFame from "../../modals/ContactFame";
import CurrentUserContext from "../../../hooks/currentUserContext";
function LandingPageBottomAppbar() {
  const navigate = useNavigate();

  const footerButtons = [
    { id: 0, title: "App Demo", navigateTo: "/demo" },
    { id: 1, title: "NFT Sale", navigateTo: "/nft-sale" },
    { id: 2, title: "About Fame", navigateTo: "/about" },
    { id: 3, title: "White Paper", navigateTo: "/" },
    { id: 4, title: "Press Kit", navigateTo: "/press-kit" },
    { id: 5, title: "Contact", navigateTo: "/" },
  ];

  function SocialLinks(props: any) {
    return (
      <Link
        href={props.link}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <button
          id="social-button"
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          <img
            alt="social-button"
            src={props.src}
            style={{ width: `${props.width}` }}
          />
        </button>
      </Link>
    );
  }

  const [open, setOpen] = useState(false);

  const {
    emailSent,
    setCurrentUser
  }: any = useContext(CurrentUserContext);
  
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    if (emailSent) setOpenSnackbar(true)
  }, [emailSent])
  
  const handleCloseSnackBar = () => {
    setCurrentUser({
      emailSent: false
    })
    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  return (
    <>
      {open && <ContactFame isOpen={open} handleClose={() => setOpen(false)} />}
      <Paper
        sx={{
          bottom: 0,
          left: 0,
          right: 0,
          height: "200px",
          paddingTop: "10px",
          background: "#000000",
          marginTop: "20px",
        }}
        elevation={3}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          {footerButtons.map((button) => {
            if (button.title === "White Paper") {
              return (
                <Link
                  key={button.id}
                  href={process.env.PUBLIC_URL + "/fame_whitepaper.pdf"}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      marginTop: "-6px",
                      fontSize: "12px",
                      color: "white",
                      textTransform: "none",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    White Paper
                  </Button>
                </Link>
              );
            }

            if (button.title === "Contact") {
              return (
                <Button
                  key={button.id}
                  size="small"
                  onClick={() => {
                    setOpen(true)
                  }}
                  sx={{
                    marginTop: "-6px",
                    fontSize: "12px",
                    color: "white",
                    textTransform: "none",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  {button.title}
                </Button>
              )
            }

            return (
              <Button
                key={button.id}
                size="small"
                onClick={() => {
                  navigate(button.navigateTo);
                }}
                sx={{
                  marginTop: "-6px",
                  fontSize: "12px",
                  color: "white",
                  textTransform: "none",
                  "&:hover": {
                    color: "red",
                  },
                }}
              >
                {button.title}
              </Button>
            );
          })}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: "10px" }}
          >
            <SocialLinks
              link="https://discord.gg/famelabs"
              src={discord}
              width="80px"
            />
            <SocialLinks
              link="https://youtube.com/@dexyfame3034"
              src={youtube}
              width="60px"
            />
            <SocialLinks
              link="https://twitter.com/famebydexy?"
              src={twitter}
              width="25px"
            />
            <iframe src="https://egld.community/api/products/2e1aed8a-e6b4-43ab-8bec-b2ea9fc9656b/upvotes/embed?theme=secondary&size=sm" width="136" height="40" style={{borderRadius: '8px', border: 'none', width: '136px', height: "40px"}}></iframe>
          </Stack>
        </Stack>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="Email Sent"
        action={action}
      />
    </>
  );
}

export default LandingPageBottomAppbar;
