import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY as string, {
// mixpanel.init("4b15509aa06f730103d2b5a931f7886f", {
  debug: true
});

const track = (name: string, props?: any) => mixpanel.track(name, props);

export { track };
