import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const ProfileTextfield = (props: {
  placeholder: string;
  label: string;
  type: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<unknown>>;
}) => {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.onChange(value);
  };

  return (
    <Stack direction='column'>
      <Typography color='white' fontSize='12px' sx={{ marginTop: '12px' }}>
        {props.label}
      </Typography>
      <TextField
        hiddenLabel
        value={props.value}
        variant='filled'
        size='small'
        placeholder={props.placeholder}
        type={props.type}
        InputProps={{ disableUnderline: true }}
        onChange={handleTextChange}
        sx={{
          input: {
            background: 'white',
            borderRadius: '15px',
            fontSize: '12px'
          }
        }}
      />
    </Stack>
  );
};

export default ProfileTextfield;
