import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function PasswordTextField(props: {
  label: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<unknown>>;
  // inputProps: Record<string, unknown>
}) {

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.onChange(value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Stack direction='column' paddingBottom="1.5rem">
      <Typography color='white' fontSize='12px' sx={{ marginTop: '12px' }}>
        {props.label}
      </Typography>
      <FormControl variant="filled" required>
        <OutlinedInput
          type={showPassword ? 'text' : 'password'}
          size="small"
          value={props.value}
          placeholder="Enter your password"
          onChange={handleTextChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            autoComplete: 'new-password',
          }}
          // inputProps={props.inputProps}
          sx={{
            background: 'white',
            borderRadius: '15px',
            fontSize: '12px'
          }}
        />
      </FormControl>
    </Stack>
  );
}

export default PasswordTextField