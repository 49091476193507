import React from 'react'

function DownloadLink({ logo, fileName, buttonText }: any) {
  return (
    <a href={logo} className="download" target="_blank" rel="noopener noreferrer" download={fileName}>
      {buttonText}
    </a>
  )
}

export default DownloadLink