import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { routeNames } from 'routes';

const WalletConnect = () => {
  const navigate = useNavigate();
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  return (
    <div style={{ top: 20, right: 25, position: 'fixed', zIndex: 5 }}>
      <Button
        className='wallet-connect-btn'
        variant='contained'
        size='medium'
        sx={{
          backgroundColor: '#FF0000',
          color: '#fff',
          textTransform: 'none',
          borderRadius: '15px',
          '&:hover': {
            backgroundColor: '#fff',
            color: '#FF0000'
          },
          '&.active': {
            backgroundColor: '#fff',
            color: '#FF0000'
          }
        }}
        onClick={isLoggedIn ? handleLogout : handleLogin}
      >
        {isLoggedIn ? 'Disconnect' : 'Connect Wallet'}
      </Button>
    </div>
  );
};

export default WalletConnect;
