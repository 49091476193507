import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

function SpectateAgainModal(props: any) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(props.open);

  const backtoHome = () => {
    navigate("/demo");
  };
  const spectateAgain = () => {
    backtoHome();
  };

  const handleClose = () => {
    setOpen(false);
    backtoHome();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            backgroundColor: "white",
            border: "solid 3px red",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography align="center" fontWeight={600} fontSize={18}>
              Would You Like to Spectate Again?
            </Typography>
          </DialogTitle>
          <DialogActions
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack direction="column" spacing={3}>
              <Stack direction="row" spacing={1}>
                <Button
                  size="large"
                  sx={{ color: "black", textTransform: "none" }}
                  onClick={handleClose} // to add onclick
                >
                  No
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    bgcolor: "#FF0000",
                    borderRadius: 5,
                    textTransform: "none",
                    ":hover": {
                      bgcolor: "#FF0000",
                    },
                  }}
                  onClick={spectateAgain}
                >
                  Yes
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default SpectateAgainModal;
