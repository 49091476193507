import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tooltipIcon from '../../assets/profile/tooltipIcon.webp';

const FameTooltipTitle = (props: { text: string }) => {
  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      justifyContent='flex-start'
      sx={{ padding: '5px' }}
    >
      <img
        alt='tooltip-icon'
        src={tooltipIcon}
        style={{
          width: '20px',
          height: '20px'
        }}
      />
      <Typography fontSize='10px'> {props.text}</Typography>
    </Stack>
  );
};

export default FameTooltipTitle;
