import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import WalletConnect from 'components/WalletConnect';
import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { Address, AddressValue, ArgSerializer, BytesValue, TransactionPayload, TypedValue, U32Value } from '@multiversx/sdk-core/out';
import { convertEsdtToWei, convertWeiToEgld, useContractInteractor } from 'utils';
import { adminWallet, contractAddress, TOKEN_DECIMAL, TOKEN_ISSUE_COST } from 'config';
import { collections } from 'data';
import { IMintingStatus } from 'interfaces';
import homeButton from "assets/landing-pages/homeButton.png";
import AdminNftMintCard from './AdminNftMintCard';
import whitelist from 'data/whitelist.json';

import './index.scss';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';

const Admin = () => {
    const [sessionId, setSessionId] = useState<string>('');
    const [mintingStatus, setMintingStatus] = useState<IMintingStatus | null>(null);
    const [issuedTokenCount, setIssuedTokenCount] = useState<number>(5);
    const [setRoleCount, setSetRoleCount] = useState<number>(0);
    const [mintedEliteCount, setMinteEliteCount] = useState<number[]>(new Array(5).fill(0));
    const [mintedRegularCount, setMintedRegularCount] = useState<number[]>(new Array(5).fill(0));

    const navigate = useNavigate();
    const { address } = useGetAccountInfo();
    const { viewMethod, callMethod } = useContractInteractor();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    const initialize = async () => {
        const [mintingStatusRes, mintedEliteCountRes, mintedRegularCountRes] = await Promise.all([
            viewMethod({
                method: 'getMintingStatus',
                args: []
            }),
            viewMethod({
                method: 'getEliteCountMintedByAdmin',
                args: []
            }),
            viewMethod({
                method: 'getRegularCountMintedByAdmin',
                args: []
            })
        ]);

        if (mintingStatusRes) {
            const convertedPackPrice = await convertWeiToEgld(mintingStatusRes?.pack_price?.toNumber(), TOKEN_DECIMAL);
            const mintingStatus: IMintingStatus = {
                isSaleOpened: mintingStatusRes?.is_sale_opened,
                packPrice: convertedPackPrice,
                updatedTimestamp: mintingStatusRes?.updated_timestamp?.toNumber(),
                wlMitingPeriod: mintingStatusRes?.wl_miting_period?.toNumber(),
                issuedTokenCount: mintingStatusRes?.issued_token_count?.toNumber(),
                setRoleCount: mintingStatusRes?.set_role_count?.toNumber(),
                currentPackIndex: mintingStatusRes?.current_pack_index?.toNumber(),
            };
            console.log("mintingStatus", mintingStatus);

            setMintingStatus(mintingStatus);
            setIssuedTokenCount(mintingStatusRes?.issued_token_count?.toNumber());
            setSetRoleCount(mintingStatusRes?.set_role_count?.toNumber());
        }

        if (mintedEliteCountRes) {
            setMinteEliteCount(mintedEliteCountRes.map((item: any) => item?.toNumber()));
        }

        if (mintedRegularCountRes) {
            setMintedRegularCount(mintedRegularCountRes.map((item: any) => item?.toNumber()));
        }
    }

    const handleIssueToken = async (index: number) => {
        try {
            if (index + 1 <= issuedTokenCount) {
                return alert("Already issued");
            }

            if (index > issuedTokenCount) {
                return alert("Issue in order");
            }

            const { collectionName, collectionTicker } = collections[index];
            const args: TypedValue[] = [
                BytesValue.fromUTF8(collectionName),
                BytesValue.fromUTF8(collectionTicker),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`issueToken@${argumentsString}`);

            const convertedValue = await convertEsdtToWei(TOKEN_ISSUE_COST);
            const tx = {
                value: convertedValue,
                data: data,
                receiver: contractAddress,
                gasLimit: '100000000'
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleSetRoles = async (collectionIndex: number) => {
        try {
            // if (collectionIndex <= setRoleCount) {
            //     return alert("Already issued");
            // }

            // if (collectionIndex > setRoleCount + 1) {
            //     return alert("Try in order");
            // }

            const args: TypedValue[] = [
                new U32Value(collectionIndex.valueOf())
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`setLocalRoles@${argumentsString}`);

            const tx = {
                value: 0,
                data: data,
                receiver: contractAddress,
                gasLimit: '100000000'
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleOpenSale = async () => {
        try {
            const tx = {
                value: 0,
                data: 'openSale',
                receiver: contractAddress,
                gasLimit: '10000000'
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleCloseSale = async () => {
        try {
            const tx = {
                value: 0,
                data: 'closeSale',
                receiver: contractAddress,
                gasLimit: '10000000'
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleEliteMint = async (collectionIndex: number, amount: number) => {
        console.log("collectionIndex", collectionIndex, amount)
        try {
            const args: TypedValue[] = [
                new U32Value(collectionIndex.valueOf()),
                new U32Value(amount.valueOf()),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`mintEliteNftsForAdmin@${argumentsString}`);

            const tx = {
                value: 0,
                data: data,
                receiver: contractAddress,
                gasLimit: 10000000 * amount
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleRegularMint = async (collectionIndex: number, amount: number) => {
        try {
            const args: TypedValue[] = [
                new U32Value(collectionIndex.valueOf()),
                new U32Value(amount.valueOf()),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`mintRegularNftsForAdmin@${argumentsString}`);

            const tx = {
                value: 0,
                data: data,
                receiver: contractAddress,
                gasLimit: 10000000 * amount
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleSetCollectionIds = async () => {
        try {
            const collectionIds = await viewMethod({
                method: 'getCollectionIds',
                args: []
            });

            if (collectionIds.length == 5) {
                return toast.warn("Already set");
            }

            const args: TypedValue[] = [];
            collections.map(item => {
                args.push(BytesValue.fromUTF8(item.collectionId.valueOf()));
            });

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`setCollectionids@${argumentsString}`);

            const tx = {
                value: 0,
                data: data,
                receiver: contractAddress,
                gasLimit: 10000000
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleClearCollectionIds = async () => {
        try {
            const tx = {
                value: 0,
                data: 'clearCollectionids',
                receiver: contractAddress,
                gasLimit: 10000000
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleSetWhitelist = async () => {
        try {
            const account = await refreshAccount();
            if (!account) {
                return toast.warn("Invaild account")
            }

            const args1: TypedValue[] = [];
            whitelist.slice(0, 500).map(item => {
                args1.push(new AddressValue(new Address(item.valueOf())));
            });
            const { argumentsString } = new ArgSerializer().valuesToString(args1);
            const data1 = new TransactionPayload(`addWhitelistUsers@${argumentsString}`);

            const args2: TypedValue[] = [];
            whitelist.slice(500).map(item => {
                args2.push(new AddressValue(new Address(item.valueOf())));
            });
            const res2 = new ArgSerializer().valuesToString(args2);
            const data2 = new TransactionPayload(`addWhitelistUsers@${res2.argumentsString}`);

            const tx = [
                {
                    value: 0,
                    data: data1,
                    receiver: contractAddress,
                    gasLimit: 600000000,
                    nonce: account.nonce
                },
                {
                    value: 0,
                    data: data2,
                    receiver: contractAddress,
                    gasLimit: 600000000,
                    nonce: (account.nonce + 1)
                }
            ];

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const handleClearWhitelist = async () => {
        try {
            const tx = {
                value: 0,
                data: 'clearWhitelistUsers',
                receiver: contractAddress,
                gasLimit: 60000000
            };

            const sessionId = await callMethod({ tx: tx });
            console.log('sessionId', sessionId);
            setSessionId(sessionId);
        } catch (e) {
            console.log('e', e);
        }
    };

    useEffect(() => {
        (async () => {
            if (transactionStatus.isSuccessful) {
                setIssuedTokenCount(issuedTokenCount + 1);
                await initialize();
                console.log('isSuccessful');
            } else if (transactionStatus.isFailed) {
                console.log('isFailed');
            } else if (transactionStatus.isCancelled) {
                console.log('isCancelled');
            }
        })();
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, [address]);

    useEffect(() => {
        if (address && address !== adminWallet) {
            navigate('/');
        }
    }, [address]);

    return (
        <div className='admin-page'>
            <div className='home-page-btn'>
                <button onClick={() => navigate("/")}>
                    <img src={homeButton} alt="nft-mint-button" />
                </button>
            </div>

            <WalletConnect />
            <div className='admin-page-wrap'>
                <div className='sale-control-btn-container'>
                    <button
                        className='sale-control-btn'
                        onClick={mintingStatus?.isSaleOpened ? handleCloseSale : handleOpenSale}
                    >
                        {mintingStatus?.isSaleOpened ? 'Close Sale' : 'Open Sale'}
                    </button>
                </div>

                <hr />

                <div className='issue-token-container'>
                    <h3>Issue Tokens for collections</h3>
                    <div className='issue-token-btn-group'>
                        {
                            collections.map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        disabled={issuedTokenCount >= index + 1}
                                        className='issue-token-btn'
                                        onClick={() => handleIssueToken(index)}
                                    >
                                        {item.collectionName}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>

                <hr />

                <div className='set-roles-container'>
                    <h3>Set Roles for collections</h3>
                    <div className='set-roles-btn-group'>
                        {
                            collections.map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        disabled={issuedTokenCount < index + 1}
                                        className='set-roles-btn'
                                        onClick={() => handleSetRoles(index + 1)}
                                    >
                                        {item.collectionName}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>

                <hr />

                {/* <div className='manage-collection-container'>
                    <h3>Manage Collection IDs</h3>
                    <div className='button-group'>
                        <button onClick={handleSetCollectionIds}>Set</button>
                        <button onClick={handleClearCollectionIds}>Clear</button>
                    </div>
                </div> */}

                <div className='manage-collection-container'>
                    <h3>Manage Whitelist</h3>
                    <div className='button-group'>
                        <button onClick={handleSetWhitelist}>Set</button>
                        <button onClick={handleClearWhitelist}>Clear</button>
                    </div>
                </div>

                <hr />

                {
                    collections.map((item, index) => {
                        return (
                            <AdminNftMintCard
                                key={index}
                                index={index}
                                mintedEliteNftCount={mintedEliteCount[index]}
                                mintedRegularNftCount={mintedRegularCount[index]}
                                handleEliteMint={handleEliteMint}
                                handleRegularMint={handleRegularMint}
                            />
                        )
                    })
                }
            </div>
        </div >
    );
};

export default Admin;