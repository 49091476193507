import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Stack, Typography } from '@mui/material';
import HomeButton from 'components/buttons/HomeButton';
import WalletConnect from 'components/WalletConnect';
import fameLogo from '../../../assets/demo/images/logo.png';
import mobileBG from '../../../assets/demo/images/mobileBG.png';
import desktopBG from '../../../assets/demo/images/desktopBG.png';

function LandingPageTopAppbar({ heading }: any) {
  const matches = useMediaQuery('(min-width:600px)');
  const bg = matches ? `url(${desktopBG})` : `url(${mobileBG})`;

  return (
    <>
      <HomeButton page='landing-pages' />
      <WalletConnect />
      <Paper
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          backgroundImage: matches
            ? `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%), ${bg}`
            : `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0)  45%), ${bg}`,
          backgroundSize: 'cover',
          borderRadius: '0px',
          zIndex: matches ? -2 : 0
        }}
        elevation={3}
      >
        <Stack
          direction='column'
          alignItems='center'
          sx={{
            paddingTop: matches ? '2rem' : '4rem',
            paddingBottom: matches ? '15rem' : '1rem'
          }}
        >
          <img
            alt='fame-logo'
            src={fameLogo}
            style={{
              width: matches ? '90%' : '80%',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: matches ? '200px' : '200px'
            }}
          />
          <Typography
            color='white'
            fontSize='12px'
            fontWeight={400}
            sx={{ textShadow: '2px 2px 2px black' }}
          >
            The World&apos;s Web3 Social Media
          </Typography>
          <Typography
            color='white'
            fontSize='24px'
            fontWeight={600}
            sx={{ textShadow: '2px 2px 2px black' }}
          >
            {heading}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
}

export default LandingPageTopAppbar;
