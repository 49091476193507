import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import eliminated from "../../assets/demo/gif/eliminated.gif";
import winner from "../../assets/demo/gif/winner.gif";

function VideoFrameDiv({ video, profilePic, name, index }: any) {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div style={{ backgroundColor: "black", position: "relative" }}>
      <img
        id={`winner-effect-${index}`}
        alt="eliminated-effect"
        src={winner}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: matches ? "16rem" : "10rem",
          height: matches ? "13rem" : "8rem",
          marginTop: matches ? "-8rem" : "-5rem",
          marginLeft: matches ? "-8rem" : "-5rem",
          zIndex: 9, // trigger,
          visibility: "hidden",
        }}
      />
      <img
        id={`effect-${index}`}
        alt="eliminated-effect"
        src={eliminated}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "6rem",
          height: "6rem",
          marginTop: "-3rem",
          marginLeft: "-3rem",
          zIndex: 9, // trigger,
          visibility: "hidden",
        }}
      />
      <div>
        {video}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={0}
        >
          <div
            style={{
              position: "absolute",
              paddingBottom: matches ? "6rem" : "5rem",
              paddingRight: "0.5rem",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={profilePic}
              sx={{
                width: matches ? "40px" : "30px",
                height: matches ? "40px" : "30px",
                backgroundColor: "#FF0000",
                border: "1px solid #FF0000",
              }}
            />
          </div>
          <div style={{ backgroundColor: "#111111", width: "100%" }}>
            <Typography
              fontSize={matches ? "12px" : "10px"}
              sx={{ color: "#fff", textAlign: "center" }}
            >
              {name}
            </Typography>
          </div>
        </Stack>
      </div>
    </div>
  );
}

export default VideoFrameDiv;
