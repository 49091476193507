import React from 'react';
import Stack from '@mui/material/Stack';
import starBullet from 'assets/nft-page/star-bullet.png';

const NFTPageTypography = (props: any) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ paddingLeft: '25px', paddingRight: '25px' }}
    >
      {props.bullet ? (
        <img
          alt='star-bullet'
          src={starBullet}
          style={{ width: '10px', height: '10px', marginRight: '5px' }}
        />
      ) : (
        <div />
      )}
      {props.children}
    </Stack>
  );
};

export default NFTPageTypography;
