import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeButton from 'components/buttons/HomeButton';
import ProfileTextfield from 'components/profilePage/ProfileTextField';
import FameGlobalButton from 'components/buttons/FameGlobalButton';
import FameTooltip from 'components/profilePage/FameTooltip';
import FameTooltipTitle from 'components/profilePage/FameTooltipTitle';
import CurrentUserContext from 'hooks/currentUserContext';
import profileBg from '../../assets/profile/profileBg.jpg';
import fameLogo from '../../assets/demo/images/logo.png';
import fameLevel from '../../assets/profile/levelIcon.webp';
import fameToken from '../../assets/profile/fameTokenIcon.webp';
import fameXP from '../../assets/profile/starIcon.webp';
import profileDefault from '../../assets/profile/profileDefaultIcon.png';
import comingSoonIcon from '../../assets/fame-club/comingSoonIconStatic.png';

function ProfilePage() {
  const navigate = useNavigate();

  const [currentUserID, setCurrentUserID] = useState('');
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [userInfo, setUserInfo] = useState<any>([]);
  const [openMyNFTs, setOpenMyNFTs] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const avatarSize = matches ? '120px' : '100px';

  useEffect(() => {
    setCurrentUserID(localStorage.getItem('fameStar') as string);
  }, [currentUserID]);

  const handleChange = (key: string) => (value: unknown) => {
    setUserInfo({
      ...userInfo,
      [key]: value
    });
  };

  const setEditProfile = () => {
    setOpenEditProfile(!openEditProfile);
  };

  const setMyNFTs = () => {
    setOpenMyNFTs(!openMyNFTs);
  };

  const FameStats = (props: {
    src: string;
    alt: string;
    value: number;
    toolTipTitle: string;
  }) => {
    return (
      <FameTooltip
        title={<FameTooltipTitle text={props.toolTipTitle} />}
        placement='left'
      >
        <Stack direction='row' alignItems='center' spacing={2}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            sx={{ width: '50px' }}
          >
            <img
              alt={props.alt}
              src={props.src}
              style={{
                width: props.alt === 'fame-level' ? '40px' : '25px',
                height: props.alt === 'fame-level' ? '20px' : '25px'
              }}
            />
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            sx={{ width: '40px' }}
          >
            <Typography color='white' fontSize='14px'>
              {props.value}
            </Typography>
          </Stack>
        </Stack>
      </FameTooltip>
    );
  };

  const ComingSoonPlaceholder = (props: { id: string }) => {
    return (
      <div
        id={props.id}
        style={{
          height: matches ? '170px' : '150px',
          borderRadius: '15px',
          border: '1px solid gray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          alt='coming-soon-icon'
          src={comingSoonIcon}
          style={{
            width: props.id === 'video' ? '80%' : '30%'
          }}
        />
      </div>
    );
  };

  const NFTSectionButton = (props: {
    title: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <Button
        size='small'
        sx={{
          textTransform: 'none',
          color: 'white',
          fontWeight: 400
        }}
        onClick={props.onClick}
      >
        {props.title}
      </Button>
    );
  };

  const fetchUserInfo = async () => {
    await fetch(`https://fame.cool/user/${currentUserID?.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('fameConnect')}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setUserInfo(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchUserInfo();
  }, [currentUserID]);

  const editProfile = async () => {
    await fetch(`https://fame.cool/user/${currentUserID?.toString()}`, {
      method: 'PATCH',
      body: JSON.stringify({
        username: userInfo.username,
        email: userInfo.email,
        hashtags: userInfo.hashtags,
        about: userInfo.about
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('fameConnect')}`,
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then((response) => response.json())
  };

  return (
    <>
      <HomeButton page='landing-pages' />
      <Box
        sx={{
          backgroundImage: `url(${profileBg})`,
          height: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          justifyContent: 'center',
          display: 'flex',
          margin: 0
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            width: matches ? '35%' : '100%',
            height: '100%',
            minHeight: '100vh'
          }}
        >
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{ padding: matches ? '30px 40px' : '50px 20px' }}
            spacing={2}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              sx={{ marginBottom: '20px' }}
            >
              <img
                alt='fame-logo'
                src={fameLogo}
                style={{
                  width: '20%'
                }}
              />
              <Typography
                color='white'
                fontSize={matches ? '32px' : '24px'}
                fontWeight={800}
              >
                Profile
              </Typography>
            </Stack>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={4}>
                <Stack
                  direction='column'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={1}
                >
                  <FameStats
                    src={fameToken}
                    alt='fame-token'
                    toolTipTitle='Win Fame Tokens by entering competitions. Earn them by increasing your level.'
                    value={
                      userInfo.fameTokens === null ? 0 : userInfo.fameTokens
                    }
                  />
                  <FameStats
                    src={fameXP}
                    alt='fame-xp'
                    toolTipTitle='Fame Stars show your popularity and status. Do more on Fame to earn stars and level up.'
                    value={userInfo.fameXP === null ? 1 : userInfo.fameXP}
                  />
                  <FameStats
                    src={fameLevel}
                    alt='fame-level'
                    toolTipTitle='Your Fame Level rises as you earn stars by making great videos.'
                    value={userInfo.fameLevel === null ? 1 : userInfo.fameLevel}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  spacing={1}
                >
                  <Avatar
                    src={profileDefault}
                    sx={{
                      backgroundColor: 'red',
                      width: avatarSize,
                      height: avatarSize
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <div />
                {/* <Stack
                  sx={{
                    backgroundColor: 'black',
                    border: '2px white solid',
                    borderRadius: '10px'
                  }}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography color='white' fontSize='12px'>
                    My NFTs
                  </Typography>
                </Stack> */}
              </Grid>
            </Grid>
            {openEditProfile ? (
              <div />
            ) : (
              <Stack direction='column' alignItems='center' spacing={1}>
                <Typography color='white' fontSize='24px' fontWeight={600}>
                  {userInfo.username}
                </Typography>
                <Typography color='white' fontSize='12px'>
                  {userInfo.email}
                </Typography>
                <Typography color='lightGray' fontSize='12px'>
                  {userInfo.about === null ? '' : userInfo.about}
                </Typography>
              </Stack>
            )}
            <Stack direction='row'>
              <Button
                size='small'
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  color: '#808080',
                  '&:hover': {
                    color: '#FF0000'
                  }
                }}
                onClick={() => {
                  localStorage.clear();
                  navigate('/login');
                }}
              >
                Sign out
              </Button>
              <Button
                startIcon={<EditIcon />}
                size='small'
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  color: openEditProfile ? 'red' : '#808080'
                }}
                onClick={() => {
                  setEditProfile();
                }}
              >
                Edit Profile
              </Button>
            </Stack>
            {openEditProfile ? (
              <Stack direction='column' spacing={2} sx={{ width: '90%' }}>
                <div>
                  <ProfileTextfield
                    placeholder={userInfo.username}
                    type='text'
                    label='Username'
                    value={userInfo.username}
                    onChange={handleChange('username')}
                  />
                  <ProfileTextfield
                    placeholder={userInfo.email}
                    type='text'
                    label='Email'
                    value={userInfo.email}
                    onChange={handleChange('email')}
                  />
                  <ProfileTextfield
                    placeholder={
                      userInfo.hashtags === null
                        ? 'Enter your hashtags'
                        : userInfo.hashtags
                    }
                    type='text'
                    label='Hashtag'
                    value={userInfo.hashtags === null ? '' : userInfo.hashtags}
                    onChange={handleChange('hashtags')}
                  />
                  <ProfileTextfield
                    placeholder={
                      userInfo.about === null
                        ? 'Tell us about yourself'
                        : userInfo.about
                    }
                    type='text'
                    label='About'
                    value={userInfo.about === null ? '' : userInfo.about}
                    onChange={handleChange('about')}
                  />
                </div>
                <div
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}
                >
                  <Stack direction='row' spacing={1}>
                    <FameGlobalButton
                      title='Cancel'
                      color='black'
                      hoverColor='#1C1C1C'
                      onClick={() => {
                        setOpenEditProfile(false);
                      }}
                    />
                    <FameGlobalButton
                      title='Save'
                      color='red'
                      hoverColor='white'
                      onClick={() => {
                        setOpenEditProfile(false);
                        editProfile();
                      }}
                    />
                  </Stack>
                </div>
              </Stack>
            ) : (
              <div />
            )}
            <Stack direction='column' spacing={1} sx={{ width: '100%' }}>
              <FameTooltip
                title={
                  <FameTooltipTitle text='Coming Soon you’ll be able to post your own videos, enter competitions, and vote on other people’s.' />
                }
                placement='left'
              >
                <Typography color='white' fontSize='14px' fontWeight={600}>
                  My Videos
                </Typography>
              </FameTooltip>
              <Grid container spacing={1}>
                {/* to change into map function */}
                <Grid item xs={3}>
                  <ComingSoonPlaceholder id='video' />
                </Grid>
                <Grid item xs={3}>
                  <ComingSoonPlaceholder id='video' />
                </Grid>
                <Grid item xs={3}>
                  <ComingSoonPlaceholder id='video' />
                </Grid>
                <Grid item xs={3}>
                  <ComingSoonPlaceholder id='video' />
                </Grid>
              </Grid>
            </Stack>
            <Stack direction='column' sx={{ width: '100%', marginTop: '10px' }}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
              >
                <FameTooltip
                  title={
                    <FameTooltipTitle text='Coming Soon, this will be your gallery for any Fame NFTs you acquire.' />
                  }
                  placement='left'
                >
                  <Button
                    endIcon={
                      openMyNFTs ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                    size='small'
                    sx={{
                      textTransform: 'none',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: 600
                    }}
                    onClick={() => {
                      setMyNFTs();
                    }}
                  >
                    My NFTs
                  </Button>
                </FameTooltip>
              </Stack>
              {openMyNFTs ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Stack
                      direction='column'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                    >
                      <NFTSectionButton
                        title='Register NFTs'
                        onClick={() => {
                          return;
                        }}
                      />
                      <NFTSectionButton
                        title='Mint NFTs'
                        onClick={() => {
                          return;
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ComingSoonPlaceholder id='nft' />
                  </Grid>
                </Grid>
              ) : (
                <div />
              )}
            </Stack>
            {/* <FameGlobalButton
              title='Help'
              color='red'
              hoverColor='white'
              onClick={() => {
                console.log('hello');
              }}
            /> */}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default ProfilePage;
