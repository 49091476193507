import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppState } from 'react-native';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import DanceCompetitionTitle from '../components/demo/DanceCompetitionTitle';
import VideoFrameDiv from '../components/demo/VideoFrameDiv';
import getRandomVideos from '../utils/getRandomVideos';
import CountdownTimer from '../components/demo/CountdownTimer';
import YouWonDisplay from '../components/demo/YouWonDisplay';
import DanceAgainModal from '../components/modals/DanceAgainModal';
import LevelUpDisplay from '../components/demo/LevelUpDisplay';
import LoadingScreen from '../components/demo/LoadingScreen';
import CurrentUserContext from '../hooks/currentUserContext';
import bgMusic from '../assets/demo/audio/music.mp3';
import eliminated from '../assets/demo/audio/eliminated.mp3';
import countdownSound from '../assets/demo/audio/countdown-sound.mp3';
import winnerSound from '../assets/demo/audio/winner.mp3';
import { slowConnectionTypes } from '../utils/constants/connectionTypes';

const containerDesktop = {
  width: '50%',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingBottom: '50px'
};

const containerMobile = {
  overflow: 'auto',
  top: '11.5em',
  bottom: '1.4em',
  left: '0px',
  right: '0px',
  position: 'fixed',
  bgColor: 'black',
  zIndex: -2
};

function DancerPage() {
  const { id } = useParams();

  const {
    setCurrentUser,
    levelUp,
    username,
    userProfilePhoto,
    ...currentUser
  }: any = useContext(CurrentUserContext);
  const matches = useMediaQuery('(min-width:600px)');

  const videoRef = useRef(null);
  const navigate = useNavigate();

  const [dbVideosData, setDbVideosData] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const [openCountdown, setOpenCountdown] = useState(true);
  const [winner, setWinner] = useState(false);
  const [danceAgainModal, setDanceAgainModal] = useState(false);
  const [competitionDetails, setCompetitionDetails] = useState<any>({});
  const [winnerDetails, setWinnerDetails] = useState<any>({});
  const [addCountdown, setAddCountdown] = useState(false);

  //check when videos are completely loaded
  const [loadCount, setLoadCount] = useState(0);

  const connectionType =
    (window?.navigator as any)?.connection?.effectiveType || '';
  const isSlowConnection = slowConnectionTypes.includes(connectionType);

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      appState.current = nextAppState;
      if (appState.current.match(/inactive|background/)) {
        stopCamera()
        navigate("/demo");
        
      }
    });

    return () => {
      subscription.remove();
    };
  }, [navigate]);

  const setVideoLoaded = () => {
    if (!isSlowConnection) {
      if (loadCount < 2) {
        setLoadCount(loadCount + 1);
      } else {
        setAddCountdown(true);
        (document.getElementById('countdown-sound') as any).play();
      }
    }
  };

  if (
    (performance.getEntriesByType('navigation')[0] as any)?.type === 'reload'
  ) {
    window.location.href = '/demo';
    console.info('This page is reloaded');
  }

  const getVideo = () => {
    const video: any = videoRef.current;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: false, video: { width: matches ? 1920 : 250, height: matches ? 1080 : 300 } })
        .then(function (stream) {
          window.localStream = stream;
          if ('srcObject' in video) {
            video.srcObject = stream;
          } else {
            video.src = window.URL.createObjectURL(stream as any);
          }
          video.onloadedmetadata = function () {
            video.play();
          };
        });
    }
  };

  const stopCamera = () => {
    if (window.localStream !== undefined) {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });

      window.localStream = null;
    }
    return;
  
  };
  

  // play video
  const playVideo = () => {
    for (let i = 0; i < 3; i++) {
      const videoToPlay: any = document.getElementById(`competitor-${i}`);
      const videoPlay = videoToPlay.play();
      videoPlay.catch(() => {
        videoPlay.play();
      });
    }

    (document.getElementById('bg-music') as any).play();
  };

  const pauseVideo = (videoID: any, eliminatedID: any) => {
    const video: any = document.getElementById(videoID);

    if (video !== null) {
      video.classList.add('competitor-eliminated');
      (document.getElementById('eliminated-sound') as any).play();
      video.pause();
    }
    
    if (eliminatedID === 'effect-2') {
      setTimeout(() => {
        setWinner(true);
        (document.getElementById('eliminated-sound') as any).pause();
        (document.getElementById('bg-music') as any).pause();
        (document.getElementById('winner-music') as any).play();
      }, 500);
    }
  };

  useEffect(() => {
    if (!isSlowConnection) {
      fetch('https://fame.cool/videos')
        .then((response) => response.json())
        .then((data) => setDbVideosData(data))
        .catch((error) => console.error(error));
    }
  }, []);

  const fetchCompetition = async () => {
    const fetchedCompetitions = await fetch(
      "https://fame.cool/competitions"
    );
    //fetch competition details
    const competitions = await fetchedCompetitions.json();
    const details =
      id && competitions.find((competition: any) => (competition.id = id));

    setCompetitionDetails(details);
  };

  useEffect(() => {
    fetchCompetition();
  }, [id]);

  useEffect(() => {
    setWinnerDetails({
      fameTokens: competitionDetails.fameTokens,
      fameXP: competitionDetails.fameXP
    });
  }, [competitionDetails, danceAgainModal]);

  window.addEventListener(
    'popstate',
    function () {
      stopCamera();
    },
    false
  );

  useEffect(() => {
    getVideo();
  }, [videoRef]);

  useEffect(() => {
    if (winner) {
      setCurrentUser({
        username,
        userProfilePhoto,
        isWinner: true
      });

      setTimeout(() => {
        setDanceAgainModal(true);
      }, 10000);
    }
  }, [winner]);

  useEffect(() => {
    getVideo();
  }, [videoRef]);

  useEffect(() => {
    const randomVideos = getRandomVideos(3, dbVideosData);
    setVideos(randomVideos);
  }, [dbVideosData]);

  useEffect(() => {
    if (isSlowConnection) {
      return;
    }
    if (addCountdown) {
      setTimeout(() => {
        if (videos.length > 0) {
          setOpenCountdown(false);
          playVideo();
        }
      }, 10000);
    }
  }, [addCountdown]);

  useEffect(() => {
    if (isSlowConnection) {
      return;
    }
    if (!openCountdown) {
      setTimeout(() => {
        pauseVideo("competitor-0", "effect-0");
        pauseVideo("competitor-1", "effect-1");
        pauseVideo("competitor-2", "effect-2");
      }, 19000);
    }
  }, [openCountdown]);

  useEffect(() => {
    if (levelUp) {
      setTimeout(() => {
        setCurrentUser({
          username,
          userProfilePhoto,
          ...currentUser,
          levelUp: false
        });
      }, 10000);
    }
  }, [levelUp]);

  return (
    <>
      {/* eliminated audio */}
      {isSlowConnection ? (
        <LoadingScreen
          loading={true}
          screenMessage='Ooops!'
          isSlowConnection={isSlowConnection}
          isOpen={isSlowConnection}
        />
      ) : (
        <>
          <audio
            id='countdown-sound'
            preload='metadata'
            style={{ display: 'none' }}
          >
            <source src={countdownSound} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
          <audio
            id='eliminated-sound'
            preload='metadata'
            style={{ display: 'hidden' }}
          >
            <source src={eliminated} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
          <audio
            id='winner-music'
            preload='metadata'
            style={{ display: 'hidden' }}
          >
            <source src={winnerSound} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
          {danceAgainModal && <DanceAgainModal open={winner} />}
          {!addCountdown ? (
            <LoadingScreen
              loading={!addCountdown}
              screenMessage='Waiting for your opponents...'
              isOpen={!addCountdown}
              isSlowConnection={isSlowConnection}
            />
          ) : (
            <CountdownTimer open={openCountdown} />
          )}
          {levelUp && <LevelUpDisplay open={levelUp} />}
          {winner && (
            <YouWonDisplay
              avatar={userProfilePhoto}
              fameToken={winnerDetails.fameTokens}
              fameXP={winnerDetails.fameXP}
              open={winner}
            />
          )}
          {!matches && (
            <Box
              top={matches ? '17em' : '9em'}
              width={matches ? '50%' : '100%'}
              position='absolute'
              right='0px'
              left='0px'
              bgcolor='black'
              zIndex={-1}
            >
              <DanceCompetitionTitle
                title={`${competitionDetails.hostUsername}'s ${competitionDetails.competitionName}`}
                token={competitionDetails.fameTokens}
                xp={competitionDetails.fameXP}
              />
            </Box>
          )}
          <Box sx={matches ? containerDesktop : containerMobile}>
            {matches && (
              <DanceCompetitionTitle
                title={`${competitionDetails.hostUsername}'s ${competitionDetails.competitionName}`}
                token={competitionDetails.fameTokens}
                xp={competitionDetails.fameXP}
              />
            )}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 10 }}
            >
              <Grid item xs={9} sm={9} md={9} lg={8}>
                <video
                  id='user-camera'
                  ref={videoRef}
                  height="100%"
                  width="100%"
                  style={{
                    objectFit: 'cover',
                    backgroundColor: '#111111'
                  }}
                  muted
                  playsInline
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <audio
                  id='bg-music'
                  preload='metadata'
                  style={{ display: 'hidden' }}
                >
                  <source src={bgMusic} type='audio/mpeg' />
                  Your browser does not support the audio element.
                </audio>
                {videos.map((video: any, index: any) => {
                  return (
                    <VideoFrameDiv
                      key={index}
                      index={index}
                      video={
                        <div className='video-overlay'>
                          <>
                            <video
                              id={`competitor-${index}`}
                              className='video-competitor'
                              height='100%'
                              width='100%'
                              src={video.urlLink}
                              // type="video/mp4"
                              onLoadedMetadata={() => {
                                setVideoLoaded();
                              }}
                              muted
                              preload='metadata'
                              playsInline
                            ></video>
                          </>
                        </div>
                      }
                      name={video.username}
                      profilePic={video.userProfilePhoto}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default DancerPage;

// const bgMusicRef = useRef(null);
// const videoCompetitorsRef = useRef(null);

// // will handle when window closes/ this is for the bgmusic
// const handleFocus = () => {
//   bgMusicRef.current.play();
//   videoCompetitorsRef.current.play()
// };

// const handleBlur = () => {
//   bgMusicRef.current.pause();
//   videoCompetitorsRef.current.pause()
// };

// useEffect(() => {
//   if (openCountdown === false && addCountdown === true){
//     window.addEventListener('focus', handleFocus);
//     window.addEventListener('blur', handleBlur);
//     return () => {
//       window.removeEventListener('focus', handleFocus);
//       window.removeEventListener('blur', handleBlur);
//     };
//   }
// }, [openCountdown, addCountdown]);
