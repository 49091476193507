import nft1Elite from 'assets/nft-page/NFT1-Elite.jpg';
import nft1 from 'assets/nft-page/NFT1.jpg';
import nft2Elite from 'assets/nft-page/NFT2-Elite.jpg';
import nft2 from 'assets/nft-page/NFT2.jpg';
import nft3Elite from 'assets/nft-page/NFT3-Elite.jpg';
import nft3 from 'assets/nft-page/NFT3.jpg';
import nft4Elite from 'assets/nft-page/NFT4-Elite.jpg';
import nft4 from 'assets/nft-page/NFT4.jpg';
import nft5Elite from 'assets/nft-page/NFT5-Elite.jpg';
import nft5 from 'assets/nft-page/NFT5.jpg';

export const collections = [
  {
    id: 1,
    collectionName: 'FameSexy',
    collectionTicker: 'FAMENFT1',
    collectionId: 'FAME1-e16050'
  },
  {
    id: 2,
    collectionName: 'FameBadBot',
    collectionTicker: 'FAMENFT2',
    collectionId: 'FAME2-2d965d'
  },
  {
    id: 3,
    collectionName: 'FameBikerGirl',
    collectionTicker: 'FAMENFT3',
    collectionId: 'FAME3-9b91ca'
  },
  {
    id: 4,
    collectionName: 'FameSamurai',
    collectionTicker: 'FAMENFT4',
    collectionId: 'FAME4-4473c4'
  },
  {
    id: 5,
    collectionName: 'FameMajorJr',
    collectionTicker: 'FAMENFT5',
    collectionId: 'FAME5-2800f2'
  }
];

export const defaultThumbnails = [
  {
    id: 0,
    thumbnail: nft1,
    title: 'NFT1',
    elite: false,
    count: 0,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT1.mp4'
  },
  {
    id: 1,
    thumbnail: nft2,
    title: 'NFT2',
    elite: false,
    count: 123,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT2.mp4'
  },
  {
    id: 2,
    thumbnail: nft3,
    title: 'NFT3',
    elite: false,
    count: 4561,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT3.mp4'
  },
  {
    id: 3,
    thumbnail: nft4,
    title: 'NFT4',
    elite: false,
    count: 0,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT4.mp4'
  },
  {
    id: 4,
    thumbnail: nft5,
    title: 'NFT5',
    elite: false,
    count: 0,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT5.mp4'
  },
  {
    id: 5,
    thumbnail: nft1Elite,
    title: 'NFT1 Elite',
    elite: true,
    count: 0,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT1-Elite.mp4'
  },
  {
    id: 6,
    thumbnail: nft2Elite,
    title: 'NFT2 Elite',
    elite: true,
    count: 12,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT2-Elite.mp4'
  },
  {
    id: 7,
    thumbnail: nft3Elite,
    title: 'NFT3 Elite',
    elite: true,
    count: 1,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT3-Elite.mp4'
  },
  {
    id: 8,
    thumbnail: nft4Elite,
    title: 'NFT4 Elite',
    elite: true,
    count: 0,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT4-Elite.mp4'
  },
  {
    id: 9,
    thumbnail: nft5Elite,
    title: 'NFT5 Elite',
    elite: true,
    count: 3,
    videoUrl:
      'https://fame-demo-site.nyc3.cdn.digitaloceanspaces.com/nft-videos%2FNFT5-Elite.mp4'
  }
];
