import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Paper,
  Stack,
  Typography,
  Backdrop,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Link
} from '@mui/material';
import {
  useGetAccountInfo,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import {
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  BytesValue,
  TransactionPayload,
  TypedValue,
  U32Value
} from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import {
  convertEsdtToWei,
  convertWeiToEgld,
  useContractInteractor
} from 'utils';
import {
  adminWallet,
  contractAddress,
  PACK_PRICE,
  PUBLIC_MINT_PRICE,
  TOKEN_DECIMAL,
  TOKEN_ISSUE_COST,
  VEGLD_TOKEN_ID,
  WL_MINT_PRICE
} from 'config';
import { collections, defaultThumbnails } from 'data';
import { IMintingStatus } from 'interfaces';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import logo from 'assets/demo/images/logo.png';
import NFTMintTitle from 'assets/nft-page/nft-mint.png';
import section3Bg from 'assets/nft-page/section-3-bg.png';
import mintNowOver from 'assets/nft-page/mint-now-button-over.gif';
import mintAgainSoon from 'assets/nft-page/mint-again-soon-btn.gif';
import mintNowUp from 'assets/nft-page/mint-now-button-up.gif';
import minusBtnUp from 'assets/nft-page/minus-btn-up.gif';
import minusBtnDown from 'assets/nft-page/minus-btn-down.gif';
import plusBtnDown from 'assets/nft-page/plus-btn-down.gif';
import plusBtnUp from 'assets/nft-page/plus-btn-up.gif';
import collectorPackBtnUp from 'assets/nft-page/collector-pack-btn-up.gif';
import collectorPackBtnDown from 'assets/nft-page/collector-pack-btn-down.gif';
import collectorPack from 'assets/nft-page/collector-pack.jpg';
import moonpayIcon from 'assets/nft-page/moonpay-icon.svg';
import whitelist from 'data/whitelist.json';
import ImageButton from 'components/buttons/ImageButton';
import NFTPageTypography from 'components/Typography/NFTPageTypography';

enum TokenTypeEnum {
  EGLD,
  vEGLD
}

const COLLECTION_COUNT = 5;
const AVAILABLE_LAST_PACK_INDEX = 5;
const AVAILABLE_FIRST_ELITE_NFT_INDEX = 61;
const AVAILABLE_LAST_ELITE_NFT_INDEX = 64;
const AVAILABLE_FIRST_REGULAR_NFT_INDEX = 1037;
const AVAILABLE_LAST_REGULAR_NFT_INDEX = 1332;

function NFTGallery() {
  const matches = useMediaQuery('(min-width:600px)');
  const [openPreview, setOpenPreview] = useState(false);
  const [currentThumbnailId, setCurrentThumbnailId] = useState<number>(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [collectorPackStatus, setCollectorPackStatus] = useState({
    isOpen: false,
    count: 0
  });
  const [thumbnails, setThumbnails] = useState(defaultThumbnails);

  const [sessionId, setSessionId] = useState<string>('');
  const [mintingStatus, setMintingStatus] = useState<IMintingStatus | null>(
    null
  );
  const [currentEliteNftIndexes, setCurrentEliteNftIndexes] = useState<
    number[]
  >(new Array(5).fill(0));
  const [currentRegularNftIndexes, setCurrentRegularNftIndexes] = useState<
    number[]
  >(new Array(5).fill(0));
  const [currentPackIndex, setCurrentPackIndex] = useState<number>(0);
  const [eliteNftMintedStatus, setEliteNftMintedStatus] =
    useState<boolean>(false);
  const [nftAmount, setNftAmount] = useState<number>(0);
  const [packAmount, setPackAmount] = useState<number>(0);
  const [tokenType, setTokenType] = useState<TokenTypeEnum>(TokenTypeEnum.EGLD);

  const { address } = useGetAccountInfo();
  const { viewMethod, callMethod, callMethodForPackMint } =
    useContractInteractor();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId
  });

  const totalPrice = useMemo(() => {
    let price = 0;
    if (mintingStatus) {
      if (currentThumbnailId < 5) {
        const currentTimestamp = Math.floor(Date.now() / 1000);

        const remainingWlPeriod =
          mintingStatus?.updatedTimestamp +
          mintingStatus?.wlMitingPeriod -
          currentTimestamp;

        if (remainingWlPeriod > 0) {
          price = WL_MINT_PRICE * nftAmount;
        } else {
          price = PUBLIC_MINT_PRICE * nftAmount;
        }
      } else {
        price = PACK_PRICE * packAmount;
      }
    }

    return price;
  }, [nftAmount, packAmount]);
  const fontSize = matches ? '14px' : '12px';

  const initialize = async () => {
    const [
      mintingStatusRes,
      currentEliteNftIndexesRes,
      currentRegularNftIndexesRes
    ] = await Promise.all([
      viewMethod({ method: 'getMintingStatus', args: [] }),
      viewMethod({ method: 'getCurrentEliteNftIndexes', args: [] }),
      viewMethod({ method: 'getCurrentRegularNftIndexes', args: [] })
    ]);

    if (address) {
      const args: TypedValue[] = [
        new U32Value(1),
        new AddressValue(new Address(address))
      ];

      const eliteNftMintedStatus = await viewMethod({
        method: 'getEliteNftMintedStatus',
        args: args
      });
      setEliteNftMintedStatus(eliteNftMintedStatus);
    }

    if (mintingStatusRes) {
      const convertedPackPrice = await convertWeiToEgld(
        mintingStatusRes?.pack_price?.toNumber(),
        TOKEN_DECIMAL
      );
      const mintingStatus: IMintingStatus = {
        isSaleOpened: mintingStatusRes?.is_sale_opened,
        packPrice: convertedPackPrice,
        updatedTimestamp: mintingStatusRes?.updated_timestamp?.toNumber(),
        wlMitingPeriod: mintingStatusRes?.wl_miting_period?.toNumber(),
        issuedTokenCount: mintingStatusRes?.issued_token_count?.toNumber(),
        setRoleCount: mintingStatusRes?.set_role_count?.toNumber(),
        currentPackIndex: mintingStatusRes?.current_pack_index?.toNumber()
      };
      console.log('mintingStatus', mintingStatus);

      setMintingStatus(mintingStatus);
      setCurrentPackIndex(mintingStatusRes?.current_pack_index?.toNumber());
    }

    if (currentEliteNftIndexesRes) {
      setCurrentEliteNftIndexes(
        currentEliteNftIndexesRes.map((item: any) => item.toNumber())
      );
    }
    if (currentRegularNftIndexesRes) {
      setCurrentRegularNftIndexes(
        currentRegularNftIndexesRes.map((item: any) => item.toNumber())
      );
    }
  };

  const handlePublicMint = async () => {
    try {
      const collectionIndex = currentThumbnailId + 1;
      const totalValue = PUBLIC_MINT_PRICE * nftAmount;
      const convertedValue = await convertEsdtToWei(
        Number(totalValue.toFixed(18))
      );

      let data;
      if (tokenType == TokenTypeEnum.EGLD) {
        const args = [
          new U32Value(collectionIndex.valueOf()),
          new U32Value(nftAmount.valueOf())
        ];
        const { argumentsString } = new ArgSerializer().valuesToString(args);
        data = new TransactionPayload(`publicMint@${argumentsString}`);
      } else {
        const args = [
          BytesValue.fromUTF8(VEGLD_TOKEN_ID),
          new BigUIntValue(convertedValue.valueOf()),
          BytesValue.fromUTF8('publicMint'),
          new U32Value(collectionIndex.valueOf()),
          new U32Value(nftAmount.valueOf())
        ];
        const { argumentsString } = new ArgSerializer().valuesToString(args);
        data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
      }

      const tx = {
        value: tokenType == TokenTypeEnum.EGLD ? convertedValue : 0,
        data: data,
        receiver: contractAddress,
        gasLimit: 10000000 * nftAmount
      };

      const sessionId = await callMethod({ tx: tx });
      console.log('sessionId', sessionId);
      setSessionId(sessionId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleWlMint = async () => {
    try {
      const collectionIndex = currentThumbnailId + 1;
      const totalValue = WL_MINT_PRICE * nftAmount;
      const convertedValue = await convertEsdtToWei(
        Number(totalValue.toFixed(18))
      );

      let data;
      if (tokenType == TokenTypeEnum.EGLD) {
        const args = [
          new U32Value(collectionIndex.valueOf()),
          new U32Value(nftAmount.valueOf())
        ];
        const { argumentsString } = new ArgSerializer().valuesToString(args);
        data = new TransactionPayload(`wlMint@${argumentsString}`);
      } else {
        const args = [
          BytesValue.fromUTF8(VEGLD_TOKEN_ID),
          new BigUIntValue(convertedValue.valueOf()),
          BytesValue.fromUTF8('wlMint'),
          new U32Value(collectionIndex.valueOf()),
          new U32Value(nftAmount.valueOf())
        ];
        const { argumentsString } = new ArgSerializer().valuesToString(args);
        data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
      }

      const tx = {
        value: tokenType == TokenTypeEnum.EGLD ? convertedValue : 0,
        data: data,
        receiver: contractAddress,
        gasLimit: 10000000 * nftAmount
      };

      const sessionId = await callMethod({ tx: tx });
      console.log('sessionId', sessionId);
      setSessionId(sessionId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handlePackMint = async () => {
    try {
      if (!address) {
        toast.warn('Please connect wallet.');
      }

      if (!mintingStatus) {
        return toast.warn('Smart contract is not deployed.');
      }

      if (!mintingStatus?.isSaleOpened) {
        return toast.warn('Not time to mint.');
      }

      const packAmount = 1;
      setPackAmount(packAmount);

      if (currentPackIndex + packAmount > AVAILABLE_LAST_PACK_INDEX) {
        return toast.warn(
          `The number of collector pack remaining is ${
            AVAILABLE_LAST_PACK_INDEX - currentPackIndex
          }`
        );
      }

      const totalValue = PACK_PRICE * packAmount;
      const convertedValue = await convertEsdtToWei(
        Number(totalValue.toFixed(18))
      );

      const accountInfo = await refreshAccount();
      console.log('accountInfo', accountInfo);

      if (!accountInfo) {
        return toast.warn('Account does not exist');
      }

      const tx = [];

      for (let i = 1; i <= 5; i++) {
        let data;
        if (tokenType == TokenTypeEnum.EGLD) {
          const args: TypedValue[] = [
            new U32Value(i),
            new U32Value(packAmount.valueOf())
          ];

          const { argumentsString } = new ArgSerializer().valuesToString(args);
          data = new TransactionPayload(`packMint@${argumentsString}`);
        } else {
          const args = [
            BytesValue.fromUTF8(VEGLD_TOKEN_ID),
            new BigUIntValue(convertedValue.valueOf()),
            BytesValue.fromUTF8('packMint'),
            new U32Value(i),
            new U32Value(packAmount.valueOf())
          ];
          const { argumentsString } = new ArgSerializer().valuesToString(args);
          data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
        }

        tx.push({
          value: tokenType == TokenTypeEnum.EGLD ? convertedValue : 0,
          data: data,
          receiver: contractAddress,
          gasLimit: 10000000 * 30 * packAmount,
          nonce: accountInfo?.nonce + i - 1
        });
      }

      const sessionId = await callMethodForPackMint({ tx: tx });
      console.log('sessionId', sessionId);
      setSessionId(sessionId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleNftMint = async () => {
    if (!address) {
      toast.warn('Please connect wallet.');
    }

    if (!mintingStatus) {
      return toast.warn('Smart contract is not deployed.');
    }

    if (!mintingStatus?.isSaleOpened) {
      return toast.warn('Not time to mint.');
    }

    if (currentThumbnailId < 5) {
      const currentTimestamp = await viewMethod({
        method: 'getCurrentTimestamp',
        args: []
      });

      const remainingWlPeriod =
        mintingStatus?.updatedTimestamp +
        mintingStatus?.wlMitingPeriod -
        currentTimestamp;
      if (remainingWlPeriod > 0) {
        if (!whitelist.includes(address)) {
          return toast.error(
            `Your are not whitelisted. Whitelist minting will be ended in ${new Date(
              Date.now() + remainingWlPeriod * 1000
            ).toLocaleString()}`
          );
        }
      }

      if (nftAmount <= 0) {
        return toast.warn('Invaild amount');
      }

      if (nftAmount > 60) {
        return toast.warn('You can not mint more than 60 NFTs at once');
      }

      const reaminingEliteNftCount =
        currentEliteNftIndexes[currentThumbnailId] == 0
          ? AVAILABLE_LAST_ELITE_NFT_INDEX - AVAILABLE_FIRST_ELITE_NFT_INDEX + 1
          : AVAILABLE_LAST_ELITE_NFT_INDEX -
            currentEliteNftIndexes[currentThumbnailId];

      const remainingRegularNftCount =
        currentRegularNftIndexes[currentThumbnailId] == 0
          ? AVAILABLE_LAST_REGULAR_NFT_INDEX -
            AVAILABLE_FIRST_REGULAR_NFT_INDEX +
            1
          : AVAILABLE_LAST_REGULAR_NFT_INDEX -
            currentRegularNftIndexes[currentThumbnailId];

      if (eliteNftMintedStatus) {
        if (remainingRegularNftCount < nftAmount) {
          return toast.warn('Insufficient NFT balance');
        }
      } else {
        if (reaminingEliteNftCount > 0) {
          if (remainingRegularNftCount < nftAmount - 1) {
            return toast.warn('Insufficient NFT balance');
          }
        } else {
          if (remainingRegularNftCount < nftAmount) {
            return toast.warn('Insufficient NFT balance');
          }
        }
      }

      if (remainingWlPeriod > 0) {
        await handleWlMint();
      } else {
        await handlePublicMint();
      }
    } else {
      await handlePackMint();
    }
  };

  // function plusButtonUp(e: any) {
  //   e.currentTarget.src = plusBtnUp;
  // }
  // function plusButtonDown(e: any) {
  //   e.currentTarget.src = plusBtnDown;
  // }
  // function minusButtonUp(e: any) {
  //   e.currentTarget.src = minusBtnUp;
  // }
  // function minusButtonDown(e: any) {
  //   e.currentTarget.src = minusBtnDown;
  // }

  function incrementCount() {
    setNftAmount(nftAmount + 1);
  }

  function decrementCount() {
    if (nftAmount > 0) {
      setNftAmount(nftAmount - 1);
    }
  }

  // const handleIncrementPakcCount = () => {
  //   setPackAmount(packAmount + 1);
  // };

  // const handleDecrementPakcCount = () => {
  //   if (packAmount > 0) {
  //     setPackAmount(packAmount - 1);
  //   }
  // };

  useEffect(() => {
    if (openPreview || isFullscreen || collectorPackStatus.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openPreview, collectorPackStatus.isOpen, isFullscreen]);

  useEffect(() => {
    (async () => {
      if (transactionStatus.isSuccessful) {
        await initialize();
        console.log('isSuccessful');
      } else if (transactionStatus.isFailed) {
        console.log('isFailed');
        await initialize();
      } else if (transactionStatus.isCancelled) {
        console.log('isCancelled');
      }
    })();
  }, [sessionId, hasPendingTransactions]);

  useEffect(() => {
    (async () => {
      await initialize();
    })();
  }, [address]);

  const MintAgainSoonButton = () => {
    return (
      <ImageButton
        id='mint-again-soon-button'
        width={matches ? '120px' : '120px'}
        height={matches ? '120px' : '120px'}
        src={mintAgainSoon}
        mouseOver={mintAgainSoon}
        onClick={() => {
          toast.error('Minting is currently closed. Come back soon.');
        }}
      />
    );
  };

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${section3Bg})`,
        backgroundColor: 'black',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '0px',
        marginBottom: '30px'
      }}
      elevation={3}
    >
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <Stack
          direction='row'
          alignContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          spacing={2}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: matches ? '50px' : '70px'
          }}
        >
          <img
            alt='nft-collection'
            src={NFTMintTitle}
            style={{ width: matches ? '20%' : '60%', marginBottom: '20px' }}
          />
        </Stack>
        <NFTPageTypography>
          <Typography
            color='white'
            fontSize={fontSize}
            fontWeight={600}
            paddingBottom='15px'
          >
            Select an NFT or Pack &gt; Add(+) to Mint
            <br />
            (Each design must be minted separately)
          </Typography>
        </NFTPageTypography>
        <FormControl
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '10px'
          }}
        >
          <FormLabel sx={{ color: 'white', fontSize: '12px' }}>
            Choose currency
          </FormLabel>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ marginTop: '10px' }}
          >
            <RadioGroup row>
              <FormControlLabel
                value={TokenTypeEnum.EGLD}
                control={
                  <Radio
                    size='small'
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'red'
                      }
                    }}
                    checked={tokenType == TokenTypeEnum.EGLD}
                    onChange={(e) => setTokenType(Number(e?.target?.value))}
                  />
                }
                label='EGLD'
                sx={{ color: 'white', fontSize: '12px' }}
              />
              <FormControlLabel
                value={TokenTypeEnum.vEGLD}
                control={
                  <Radio
                    size='small'
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'red'
                      }
                    }}
                    checked={tokenType == TokenTypeEnum.vEGLD}
                    onChange={(e) => setTokenType(Number(e?.target?.value))}
                  />
                }
                label='vEGLD'
                sx={{ color: 'white', fontSize: '12px' }}
              />
            </RadioGroup>
            <Link
              href='https://www.moonpay.com/buy'
              sx={{
                textDecoration: 'none',
                backgroundColor: '#ffffff',
                padding: '4px 15px 4px 15px',
                borderRadius: '15px',
                '&:hover': {
                  backgroundColor: '#f7f7f7',
                  border: '2px red solid'
                }
              }}
              target='_blank'
            >
              <img
                style={{ width: '80px' }}
                src={moonpayIcon}
                alt='moonpay-button'
              />
            </Link>{' '}
          </Stack>
        </FormControl>

        {/* ----------------FULL SCREEN-------------- */}
        {isFullscreen ? (
          <Backdrop
            sx={{
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.80)',
              zIndex: 5
            }}
            open={isFullscreen}
          >
            <Stack direction='column'>
              <IconButton
                size='large'
                style={{
                  color: 'white',
                  top: matches ? '10vh' : 0,
                  right: matches ? '30vw' : 0,
                  position: 'absolute',
                  padding: '20px'
                }}
                onClick={() => {
                  setIsFullscreen(false);
                }}
              >
                <CloseIcon
                  style={{ color: 'yellow', width: '40px', height: '40px' }}
                />
              </IconButton>
              <Stack direction='column' alignItems='center' spacing={2}>
                <Stack direction='row'>
                  <video
                    autoPlay
                    controls
                    loop
                    width={matches ? '500px' : '350px'}
                    style={{ boxShadow: '5px 5px 150px 100px black' }}
                    src={thumbnails[currentThumbnailId].videoUrl}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Backdrop>
        ) : (
          <div />
        )}

        {/* ----------------COLLECTOR PACK-------------- */}
        {collectorPackStatus.isOpen ? (
          <Backdrop
            sx={{
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.80)',
              zIndex: 5
            }}
            open={collectorPackStatus.isOpen}
          >
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <IconButton
                size='large'
                style={{
                  color: 'white',
                  top: matches ? '5vh' : 0,
                  right: matches ? '30vw' : 0,
                  position: 'absolute',
                  padding: '20px'
                }}
                onClick={() => {
                  setCollectorPackStatus({
                    ...collectorPackStatus,
                    isOpen: false
                  });
                }}
              >
                <CloseIcon
                  style={{ color: 'yellow', width: '40px', height: '40px' }}
                />
              </IconButton>
              <Stack direction='column' alignItems='center' spacing={2}>
                <Stack direction='row'>
                  <img
                    style={{
                      width: matches ? '350px' : '300px',
                      boxShadow: '5px 5px 150px 100px black'
                    }}
                    alt='collector-pack'
                    src={collectorPack}
                  />
                </Stack>
                <Stack direction='row' alignItems='center' spacing={3}>
                  <Stack direction='row' spacing={1}>
                    <img alt='fame-logo' src={logo} style={{ width: '40px' }} />
                    <Typography sx={{ textShadow: '2px 1px 2px black' }}>
                      NFT Collector Pack
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                  >
                    {/* {packAmount === 3 ? (
                    <div />
                  ) : (
                    <ImageButton
                      id='plus-button'
                      width='30px'
                      src={plusBtnUp}
                      mouseOver={plusButtonDown}
                      mouseOut={plusButtonUp}
                      //ADD PLUS BUTTON FUNCTION HERE
                      onClick={() => handleIncrementPakcCount()}
                    />
                  )} */}
                    {/* <ImageButton
                    id='plus-button'
                    width='30px'
                    src={plusBtnUp}
                    mouseOver={plusButtonDown}
                    mouseOut={plusButtonUp}
                    //ADD PLUS BUTTON FUNCTION HERE
                    onClick={() => handleIncrementPakcCount()}
                  /> */}
                    {/* <ImageButton
                    id='minus-button'
                    width='30px'
                    src={minusBtnUp}
                    mouseOver={minusButtonDown}
                    mouseOut={minusButtonUp}
                    //ADD PLUS BUTTON FUNCTION HERE
                    onClick={() => handleDecrementPakcCount()}
                  /> */}
                    {/* <div
                    style={{
                      width: '50px',
                      height: '30px',
                      backgroundColor: '#A5B99E',
                      border: '2px solid red',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      display: 'flex',
                      paddingRight: '10px'
                    }}
                  > */}
                    {/* ADD COUNTER HERE VALUE */}
                    {/* <Typography
                      fontSize='24px'
                      color='black'
                      sx={{ fontFamily: 'Normal' }}
                    >
                      {packAmount}
                    </Typography>
                  </div> */}
                  </Stack>
                </Stack>
              </Stack>
              <div style={{ marginTop: '10px' }} />
              <MintAgainSoonButton />
              {/* <ImageButton
                id='mint-now-button'
                width={matches ? '120px' : '120px'}
                height={matches ? '120px' : '120px'}
                src={mintNowUp}
                mouseOver={mintNowOver}
                onClick={() => handlePackMint()}
              /> */}
            </Stack>
          </Backdrop>
        ) : (
          <div />
        )}
        <Stack
          direction='column'
          alignItems='center'
          style={{
            marginTop: '10px',
            width: matches ? '50%' : '100%'
          }}
        >
          <Grid
            container
            sx={{
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            <Backdrop
              sx={{
                color: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.80)',
                zIndex: (theme) => theme.zIndex.drawer + 1
              }}
              open={openPreview}
            >
              <Stack direction='column'>
                <IconButton
                  size='large'
                  style={{
                    color: 'white',
                    top: matches ? '5vh' : 0,
                    right: matches ? '30vw' : 0,
                    position: 'absolute',
                    padding: '20px'
                  }}
                  onClick={() => {
                    setOpenPreview(false);
                  }}
                >
                  <CloseIcon
                    style={{ color: 'yellow', width: '40px', height: '40px' }}
                  />
                </IconButton>
                <Stack direction='column' alignItems='center' spacing={2}>
                  <Stack direction='row'>
                    {currentThumbnailId === 0 ? (
                      <div
                        style={{
                          width: '50px',
                          marginRight: matches ? '20px' : '5px'
                        }}
                      />
                    ) : (
                      <IconButton
                        style={{
                          color: 'white',
                          marginRight: matches ? '20px' : '5px'
                        }}
                        onClick={() => {
                          setCurrentThumbnailId(currentThumbnailId - 1);
                        }}
                      >
                        <ArrowBackIosIcon
                          sx={{ fontSize: matches ? '50px' : '30px' }}
                        />
                      </IconButton>
                    )}
                    <div style={{ position: 'relative' }}>
                      <IconButton
                        size='small'
                        style={{
                          color: 'white',
                          position: 'absolute',
                          top: '10px',
                          left: '10px',
                          backgroundColor: 'red'
                        }}
                        onClick={() => {
                          setIsFullscreen(true);
                          setOpenPreview(false);
                        }}
                      >
                        <PlayArrowIcon
                          sx={{
                            width: '45px',
                            height: '45px'
                          }}
                        />
                      </IconButton>
                    </div>
                    <img
                      style={{
                        width: matches ? '300px' : '250px',
                        boxShadow: '5px 5px 150px 100px black'
                      }}
                      alt='thumbnail-preview'
                      src={thumbnails[currentThumbnailId].thumbnail}
                    />

                    {currentThumbnailId === 9 ? (
                      <div
                        style={{
                          width: '50px',
                          marginRight: matches ? '20px' : '5px'
                        }}
                      />
                    ) : (
                      <IconButton
                        style={{
                          marginLeft: matches ? '20px' : '5px',
                          color: 'white'
                        }}
                        onClick={() => {
                          setCurrentThumbnailId(currentThumbnailId + 1);
                        }}
                      >
                        <ArrowForwardIosIcon
                          sx={{ fontSize: matches ? '50px' : '30px' }}
                        />
                      </IconButton>
                    )}
                  </Stack>
                  {thumbnails[currentThumbnailId].elite ? (
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                      spacing={1}
                    >
                      <img
                        alt='fame-logo'
                        src={logo}
                        style={{ width: '40px' }}
                      />
                      <Typography sx={{ textShadow: '2px 1px 2px black' }}>
                        {thumbnails[currentThumbnailId].title}
                      </Typography>
                      <div style={{ width: '120px' }}>
                        <Typography
                          fontSize='12px'
                          color='red'
                          align='center'
                          fontWeight={600}
                        >
                          ONLY AVAILABLE IN COLLECTOR PACK
                        </Typography>
                      </div>
                      <ImageButton
                        id='collector-pack-button'
                        width='70px'
                        height='35px'
                        src={collectorPackBtnUp}
                        mouseOver={collectorPackBtnDown}
                        //ADD COLLECTION PACK BUTTON FUNCTION HERE
                        onClick={() => {
                          setCollectorPackStatus({
                            ...collectorPackStatus,
                            isOpen: true
                          });
                          setOpenPreview(false);
                        }}
                      />
                    </Stack>
                  ) : (
                    <Stack direction='row' alignItems='center' spacing={3}>
                      <Stack direction='row' spacing={1}>
                        <img
                          alt='fame-logo'
                          src={logo}
                          style={{ width: '40px' }}
                        />
                        <Typography sx={{ textShadow: '2px 1px 2px black' }}>
                          {thumbnails[currentThumbnailId].title}
                        </Typography>
                      </Stack>
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        spacing={-2}
                      >
                        <ImageButton
                          id='plus-button'
                          width='30px'
                          height='30px'
                          src={plusBtnUp}
                          mouseOver={plusBtnDown}
                          //ADD PLUS BUTTON FUNCTION HERE
                          onClick={() => incrementCount()}
                        />
                        <ImageButton
                          id='minus-button'
                          width='30px'
                          height='30px'
                          src={minusBtnUp}
                          mouseOver={minusBtnDown}
                          //ADD PLUS BUTTON FUNCTION HERE
                          onClick={() => decrementCount()}
                        />

                        <div
                          style={{
                            width: '70px',
                            height: '30px',
                            backgroundColor: '#A5B99E',
                            border: '2px solid red',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            display: 'flex',
                            paddingRight: '10px'
                          }}
                        >
                          {/* ADD COUNTER HERE VALUE */}
                          <Typography
                            fontSize='24px'
                            color='black'
                            sx={{ fontFamily: 'Normal' }}
                          >
                            {nftAmount}
                          </Typography>
                        </div>
                      </Stack>
                    </Stack>
                  )}
                  <div style={{ marginTop: '10px' }} />
                  {!thumbnails[currentThumbnailId].elite ? (
                    <MintAgainSoonButton />
                  ) : (
                    // <ImageButton
                    //   id='mint-now-button'
                    //   width={matches ? '120px' : '120px'}
                    //   height={matches ? '120px' : '120px'}
                    //   src={mintNowUp}
                    //   mouseOver={mintNowOver}
                    //   onClick={() => handleNftMint()}
                    // />
                    <div />
                  )}
                </Stack>
              </Stack>
            </Backdrop>

            {thumbnails.map((thumbnail, index) => {
              return (
                <Grid item key={thumbnail.id} xs={2.4}>
                  <div style={{ position: 'relative' }}>
                    {thumbnail.elite && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px'
                        }}
                      >
                        <ImageButton
                          id='collector-pack-button'
                          width={matches ? '70px' : '5px'}
                          height={matches ? '40px' : '15px'}
                          src={collectorPackBtnUp}
                          mouseOver={collectorPackBtnDown}
                          //ADD COLLECTION PACK BUTTON FUNCTION HERE
                          onClick={() => {
                            setCollectorPackStatus({
                              ...collectorPackStatus,
                              isOpen: true
                            });
                            setOpenPreview(false);
                          }}
                        />
                      </div>
                    )}
                    <button
                      onClick={() => {
                        setOpenPreview(true);
                        setNftAmount(0);
                        setPackAmount(0);
                        setCurrentThumbnailId(thumbnail.id);
                      }}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          paddingRight: matches ? '10px' : '5px'
                        }}
                      >
                        <Typography
                          fontSize='10px'
                          color='white'
                          sx={{
                            fontFamily: 'Normal',
                            textShadow: '2px 1px 4px black'
                          }}
                        >
                          {index < 5
                            ? `${
                                currentRegularNftIndexes[index] == 0
                                  ? 0
                                  : currentRegularNftIndexes[index] -
                                    AVAILABLE_FIRST_REGULAR_NFT_INDEX +
                                    1
                              }/${
                                AVAILABLE_LAST_REGULAR_NFT_INDEX -
                                AVAILABLE_FIRST_REGULAR_NFT_INDEX +
                                1
                              }`
                            : `${
                                currentEliteNftIndexes[index % 5] == 0
                                  ? 0
                                  : currentEliteNftIndexes[index % 5] -
                                    AVAILABLE_FIRST_ELITE_NFT_INDEX +
                                    1
                              }/${
                                AVAILABLE_LAST_ELITE_NFT_INDEX -
                                AVAILABLE_FIRST_ELITE_NFT_INDEX +
                                1
                              }`}
                        </Typography>
                      </div>

                      {nftAmount > 0 &&
                        currentThumbnailId == thumbnail.id &&
                        !thumbnail.elite && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '5px',
                              right: '5px',
                              width: matches ? '60px' : '40px',
                              height: matches ? '30px' : '20px',
                              backgroundColor: '#A5B99E',
                              border: '2px solid red',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              display: 'flex',
                              paddingRight: matches ? '10px' : '5px'
                            }}
                          >
                            <Typography
                              fontSize={matches ? '24px' : '14px'}
                              color='black'
                              sx={{ fontFamily: 'Normal' }}
                            >
                              {!thumbnail.elite && nftAmount}
                            </Typography>
                          </div>
                        )}

                      <img
                        id={`${thumbnail?.id}`}
                        alt='thumbnail'
                        src={thumbnail.thumbnail}
                        style={{
                          width: '100%',
                          height: '100%',
                          border: thumbnail.elite ? '2px solid red' : 'none'
                        }}
                      />
                    </button>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <NFTPageTypography>
            <Typography
              align='center'
              color='white'
              fontSize={fontSize}
              fontWeight={400}
            >
              {`Single NFT = `}
              <br />
              {`Whitelist: ${WL_MINT_PRICE} ${TokenTypeEnum[tokenType]}`}
              <br />
              {`Public: ${PUBLIC_MINT_PRICE} ${TokenTypeEnum[tokenType]}`}
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography>
            <Typography
              align='center'
              color='white'
              fontSize={fontSize}
              fontWeight={400}
            >
              <span
                style={{
                  color: '#0086FF',
                  fontWeight: 600,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCollectorPackStatus({
                    ...collectorPackStatus,
                    isOpen: true
                  });
                }}
              >
                Collector Pack
              </span>{' '}
              {`= ${(PACK_PRICE * 5).toLocaleString()} ${
                TokenTypeEnum[tokenType]
              }`}
            </Typography>
          </NFTPageTypography>
          <div style={{ marginTop: '10px' }} />
          <NFTPageTypography>
            <Typography
              align='center'
              color='white'
              fontSize={fontSize}
              fontWeight={600}
            >
              {`TOTAL COST ${totalPrice.toLocaleString()} ${
                TokenTypeEnum[tokenType]
              }`}
            </Typography>
          </NFTPageTypography>
          <div style={{ marginTop: matches ? '10px' : '30px' }} />
          {/* <ImageButton
          id='mint-now-button'
          width={matches ? '120px' : '120px'}
          height={matches ? '120px' : '120px'}
          src={mintNowUp}
          mouseOver={mintNowOver}
          onClick={() => handleNftMint()}
        /> */}
          <MintAgainSoonButton />
          <Typography
            color='white'
            fontSize={fontSize}
            fontWeight={600}
            paddingTop='50px'
          >
            See More!
          </Typography>
          <KeyboardArrowDownIcon sx={{ color: 'white' }} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default NFTGallery;
