import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography, Backdrop } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const renderTime = ({ remainingTime }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="#D9D9D9" fontSize="72px" fontWeight="600">
        {remainingTime}
      </Typography>
      <Typography sx={{ mt: "-15px" }} color="#D9D9D9" fontSize="14px">
        seconds
      </Typography>
    </Box>
  );
};

function CountdownTimer(props: {open: boolean}) {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
      }}
      open={props.open}
    >
      <div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CountdownCircleTimer
            isPlaying
            duration={10}
            colors="#FF0000"
          >
            {renderTime}
          </CountdownCircleTimer>
          <Typography
            color="#D9D9D9"
            fontSize={matches ? "32px" : "28px"}
            fontWeight="600"
            sx={{ mt: "30px", textShadow: "2px 2px 2px black" }}
          >
            Get Ready to Dance!
          </Typography>
        </Box>
      </div>
    </Backdrop>
  );
}

export default CountdownTimer;
