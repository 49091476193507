import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import fameClubLogo from '../../assets/fame-club/fame-club-logo.png';

function SignupMessageModal(props: any) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    setOpen(false);
    navigate(`/profile`);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '20px',
            backgroundColor: 'white',
            border: 'solid 3px red'
          }
        }}
      >
        <Box
          sx={{
            padding: '20px'
          }}
        >
          <Stack justifyContent='center' alignItems='center'>
            <img src={fameClubLogo} style={{ width: '20%' }} />
          </Stack>
          <DialogTitle id='responsive-dialog-title'>
            <Typography align='center' fontWeight={600} fontSize={18}>
              You’re in!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography align='center' fontSize={14}>
              Welcome to Fame Club. Watch for announcements.
              <br />
              You can now edit your profile ⭐️
              <br />
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              size='medium'
              variant='contained'
              sx={{
                bgcolor: '#FF0000',
                borderRadius: 5,
                textTransform: 'none',
                ':hover': {
                  bgcolor: '#FF0000'
                }
              }}
              onClick={handleClose} // to add onclick
            >
              OK
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default SignupMessageModal;
