import React, { useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Backdrop } from "@mui/material";
import { Fireworks } from "@fireworks-js/react";
import levelUp from "../../assets/demo/gif/levelUp.gif";

function LevelUpDisplay(props: any) {
  const matches = useMediaQuery("(min-width:600px)");
  const ref = useRef(null);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 12,
      }}
      open={props.open}
    >
      <div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="level-up"
            src={levelUp}
            style={{ width: matches ? "900px" : "550px" }}
          />
        </Box>
        <Fireworks
          ref={ref}
          options={{ opacity: 1 }}
          style={{
            left: 0,
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />
      </div>
    </Backdrop>
  );
}

export default LevelUpDisplay;
