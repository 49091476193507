import React from 'react'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

function ComingSoonDiv(props :{title: string, vidSrc: string, details: string}) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid container
      spacing={{ xs: 2 }}
      columns={{ xs: 12 }}
      alignItems='center'
      justifyContent="center">
          <Grid item
            alignItems='center'
            justifyContent="center"
            xs={12}
          > 
            <Typography 
              color="white" 
              paddingX="50px" 
              textAlign="center" 
              fontWeight="700"
              paddingTop={matches ? "0.5rem" : "1rem"}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item 
            alignItems='center'
            justifyContent="center"
            xs={12}
          >
            <video 
              autoPlay
              loop
              height={matches ? "300px" : "200px"}
              width="100%"
              src={props.vidSrc}
              preload="metadata"
              style={{paddingTop: "2rem", paddingBottom: "2rem", textAlign: "center"}}
              muted
              playsInline
            />
          </Grid>
        <Grid item 
          alignItems='center'
          justifyContent="center"
          xs={12}
        >
          <Typography color="white" paddingX="40px" textAlign="center" fontWeight="700" paddingBottom="20px">
            {props.details}
          </Typography>
        </Grid>
        {!matches &&  <Grid item 
          alignItems='center'
          justifyContent="center"
          xs={12}
        >
          <Divider variant="middle" sx={{ backgroundColor: 'white'}} />
        </Grid> }
       
    </Grid>
  )
}

export default ComingSoonDiv;