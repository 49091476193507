import React from 'react';
import { RouteType } from '@multiversx/sdk-dapp/types';
import BottomAppbar from 'components/layout/BottomAppbar';
import LandingPageTopAppbar from 'components/layout/LandingPageLayout/LandingPageTopAppbar';
import { dAppName } from 'config';
import HomePage from 'pages/HomePage';
import AboutPage from 'pages/LandingPage/AboutPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import PressKitPage from 'pages/LandingPage/PressKitPage';
import NFTPage from 'pages/NFTPage';
import DanceSpectatorPage from 'pages/DanceSpectatorPage';
import DancerPage from 'pages/DancerPage';
import CreateProfilePage from 'pages/Profile/CreateProfilePage';
import Admin from 'pages/Admin';
import ComingSoonPage from 'pages/ComingSoonPage';
import LoginPage from 'pages/Profile/LoginPage';
import ProfilePage from 'pages/Profile/ProfilePage';
import TopAppBar from 'components/layout/TopAppBar';
import ComingSoonPageTopBar from 'components/layout/ComingSoonPageTopAppbar';
import { withPageTitle } from 'components/PageTitle';
import PrivateRoute from 'routes/PrivateRoute';

export const routeNames = {
  home: '/',
  about: '/about',
  pressKit: '/press-kit',
  demo: '/demo',
  nftSale: '/nft-sale',
  spectate: '/spectate/:id',
  dance: '/dance/:id',
  admin: '/admin',
  unlock: '/unlock',
  createProfile: '/create-profile',
  profile: '/profile',
  login: '/login',
  comingSoon: '/waitlist'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

// export const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: (
      <>
        <LandingPageTopAppbar heading='Come Play.' />
        <LandingPage />
      </>
    )
  },
  {
    path: routeNames.createProfile,
    title: 'Create Profile',
    component: (
      <>
        <CreateProfilePage />
      </>
    )
  },
  {
    path: routeNames.profile,
    title: 'Profile',
    component: (
      <>
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      </>
    )
  },
  {
    path: routeNames.login,
    title: 'Login Page',
    component: (
      <>
        <LoginPage />
      </>
    )
  },
  {
    path: routeNames.about,
    title: 'About',
    component: (
      <>
        <LandingPageTopAppbar heading='Come Play.' />
        <AboutPage />
      </>
    )
  },
  {
    path: routeNames.pressKit,
    title: 'PressKit',
    component: (
      <>
        <LandingPageTopAppbar heading='Press Kit' />
        <PressKitPage />
      </>
    )
  },
  {
    path: routeNames.demo,
    title: 'Demo',
    component: (
      <>
        <TopAppBar />
        <HomePage />
        <BottomAppbar />
      </>
    )
  },
  {
    path: routeNames.nftSale,
    title: 'NFTSale',
    component: (
      <>
        <NFTPage />
      </>
    )
  },
  {
    path: routeNames.spectate,
    title: 'Spectate',
    component: (
      <>
        <TopAppBar />
        <DanceSpectatorPage />
        <BottomAppbar />
      </>
    )
  },
  {
    path: routeNames.dance,
    title: 'Dance',
    component: (
      <>
        <TopAppBar />
        <DancerPage />
        <BottomAppbar />
      </>
    )
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: <Admin />
  },
  {
    path: routeNames.comingSoon,
    title: 'Coming Soon',
    component: (
      <>
        <ComingSoonPageTopBar />
        <ComingSoonPage />
      </>
    )
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
