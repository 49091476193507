import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Stack, Typography } from '@mui/material';
import NFTCollectionEliteTitle from '../../assets/nft-page/nft-collection-elite.png';
import NFTCollectionEliteFlowchart from '../../assets/nft-page/nft-collection-elite-flowchart.png';
import section2Bg from '../../assets/nft-page/section-2-bg.png';
import savageBlackBadge from '../../assets/nft-page/savage-black-badge.gif';
import savageBlackEliteBadge from '../../assets/nft-page/savage-black-elite-badge.webp';
import redStarBullet from '../../assets/nft-page/red-star-bullet.png';

function NFTCollectionEliteSection() {
  const matches = useMediaQuery('(min-width:600px)');

  const fontSize = matches ? '14px' : '12px';

  const NFTPageTypography = (props: any) => {
    return (
      <Stack
        direction='row'
        alignItems='center'
        sx={{ paddingLeft: '25px', paddingRight: '25px' }}
      >
        {props.bullet ? (
          <img
            alt='star-bullet'
            src={redStarBullet}
            style={{ width: '10px', height: '10px', marginRight: '5px' }}
          />
        ) : (
          <div />
        )}
        {props.children}
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${section2Bg})`,
        backgroundColor: 'black',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '0px',
        marginBottom: '70px'
      }}
      elevation={3}
    >
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <Stack
          direction='row'
          alignContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          spacing={2}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '70px'
          }}
        >
          <img
            alt='nft-collection'
            src={NFTCollectionEliteTitle}
            style={{ width: matches ? '30%' : '50%' }}
          />
        </Stack>
        <div style={{ marginTop: '10px' }} />
        <NFTPageTypography>
          <Typography color='white' fontSize={fontSize} fontWeight={600}>
            Utilities and Rewards
          </Typography>
        </NFTPageTypography>
        <div style={{ marginTop: '20px' }} />
        <NFTPageTypography>
          <Typography
            align='center'
            color='white'
            fontSize={fontSize}
            fontWeight={400}
          >
            There are 555 Fame NFTs available, divided into 5 different designs.
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography
            align='center'
            color='white'
            fontSize={fontSize}
            fontWeight={400}
          >
            All Fame NFT ELITE owners receive an exclusive SAVAGE Badge
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography color='white' fontSize={fontSize} fontWeight={400}>
            ({"An Elite version of Fame's "}
            <span style={{ color: '#0086FF', fontStyle: 'italic' }}>
              {'Blue Checkmark'}
            </span>
            )
          </Typography>
        </NFTPageTypography>
        <Stack direction='column' alignItems='center'>
          <img
            alt='nft-collection-flowchart'
            src={NFTCollectionEliteFlowchart}
            style={{ width: matches ? '50%' : '60%', marginTop: '10px' }}
          />
          <div style={{ width: '280px' }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              style={{
                margin: '10px 0px 10px'
              }}
            >
              <img
                alt='ice-clout-badge-no-glow'
                src={savageBlackBadge}
                style={{ width: '30%' }}
              />
              <img
                alt='ice-clout-badge-with-glow'
                src={savageBlackEliteBadge}
                style={{ width: '30%' }}
              />
            </Stack>
          </div>
        </Stack>
        <NFTPageTypography>
          <Typography
            align='center'
            color='white'
            fontSize={fontSize}
            fontWeight={400}
          >
            Owning 1 + NFTs ={' '}
            <span style={{ color: '#FF0000', fontWeight: 600 }}>
              SAVAGE Badge
            </span>
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography
            align='center'
            color='white'
            fontSize={fontSize}
            fontWeight={400}
          >
            Owning 1 + full set of NFTs = rare{' '}
            <span style={{ color: '#FF0000', fontWeight: 600 }}>
              Animated SAVAGE Badge
            </span>
          </Typography>
        </NFTPageTypography>
        <Stack
          direction='column'
          alignItems='center'
          style={{ marginTop: '10px' }}
        >
          <NFTPageTypography>
            <Typography color='red' fontSize={fontSize} fontWeight={400}>
              NFT Owner Benefits
            </Typography>
          </NFTPageTypography>
          <div style={{ marginBottom: '10px' }} />
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Blooming feature (Full Set Only)
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Chance to receive{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>
                3000 FAME/day
              </span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Higher discount on future{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>NFTs</span>{' '}
              and sales
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Higher chance to receive a{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>
                BAD bonus
              </span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              <span style={{ color: '#FF0000', fontWeight: 600 }}>
                {' '}
                Lunch Date{' '}
              </span>{' '}
              with your favorite creator
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Discount on ALL{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>merch</span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Future benefits and{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>perks</span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='#FF0000' fontSize={fontSize} fontWeight={600}>
              (Full Set Only) Fame Resort Stay
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Eligibility for all-expense-paid trips to{' '}
              <span style={{ color: '#FF0000', fontWeight: 600 }}>
                Fame events
              </span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              <span style={{ color: '#FF0000', fontWeight: 600 }}>
                VIP Status
              </span>{' '}
              for Fame events and celebrity
            </Typography>
          </NFTPageTypography>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default NFTCollectionEliteSection;
