import React from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import fameClub from '../../assets/landing-pages/fame-club.webp';
import nftPage from '../../assets/landing-pages/nft-page.webp';
import demo from '../../assets/landing-pages/demo.webp';
import LandingPageBottomAppbar from '../../components/layout/LandingPageLayout/LandingPageBottomAppbar';

function LandingPage() {
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:600px)');

  const LandingPageButtons = (props: {
    className: string;
    navigate: string;
    alt: string;
    width: string;
    src: string;
  }) => {
    return (
      <button
        className={props.className}
        onClick={() => {
          navigate(props.navigate);
        }}
      >
        <img
          alt={props.alt}
          src={props.src}
          style={{
            width: props.width
          }}
        />
      </button>
    );
  };

  return (
    <Box
      top={matches ? '35vh' : '45vh'}
      minHeight='50px'
      position='absolute'
      right='0px'
      left='0px'
      zIndex={-1}
    >
      <Stack
        direction={matches ? 'row' : 'column'}
        alignItems='center'
        justifyContent='center'
        spacing={4}
        sx={{ marginBottom: '50px' }}
      >
        <LandingPageButtons
          className='landing-button'
          navigate='/waitlist'
          alt='fame-club-button'
          src={fameClub}
          width={matches ? '190px' : '150px'}
        />
        <LandingPageButtons
          className='landing-app-demo-button'
          navigate='/demo'
          alt='demo-button'
          src={demo}
          width={matches ? '150px' : '100px'}
        />
        <LandingPageButtons
          className='landing-button'
          navigate='/nft-sale'
          alt='nft-mint-button'
          src={nftPage}
          width={matches ? '190px' : '150px'}
        />
      </Stack>
      <LandingPageBottomAppbar />
    </Box>
  );
}

export default LandingPage;
