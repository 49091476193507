import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import seth from '../../assets/about-page/seth.jpg';
import alliah from '../../assets/about-page/alliah.jpg';
import arden from '../../assets/about-page/arden.jpg';
import ariessa from '../../assets/about-page/ariessa.jpg';
import francis from '../../assets/about-page/francis.jpg';
import gufran from '../../assets/about-page/gufran.jpg';
import hamid from '../../assets/about-page/hamid.jpg';
import marsry from '../../assets/about-page/marsry.jpg';
import raz from '../../assets/about-page/raz.jpg';
import teofy from '../../assets/about-page/teofy.jpg';
import tim from '../../assets/about-page/tim.jpg';
import vanessa from '../../assets/about-page/vanessa.jpg';
import web3 from '../../assets/about-page/web3.png';
import coolLookOrion from '../../assets/about-page/coolLookOrion.png';
import demiourgos from '../../assets/about-page/demiourgos.png';
import ashbury from '../../assets/about-page/ashbury.png';
import lincoln from '../../assets/about-page/lincoln.png';
import nordbite from '../../assets/about-page/nordbite.png';
import LandingPageBottomAppbar from '../../components/layout/LandingPageLayout/LandingPageBottomAppbar';

function AboutPage() {
  const matches = useMediaQuery('(min-width:600px)');
  const executives = [
    {
      name: 'Seth Reid',
      position: 'CEO (Chief Executive Officer)',
      image: seth
    },
    {
      name: 'Hamid Almozaffar',
      position: 'CFO (Chief Financial Officer)',
      image: hamid
    },
    {
      name: 'Tim Turton',
      position: 'CMO (Chief Marketing Officer)',
      image: tim
    }
  ];
  const team = [
    {
      name: 'Gufran Hussain Wani',
      position: 'COO (Chief Operations Officer)',
      image: gufran
    },
    {
      name: 'Arden Zion Agarrado',
      position: 'CRO (Chief Research Officer)',
      image: arden
    },
    {
      name: 'Sheraz Abdulazeez',
      position: 'CEC (Community Engagement Coordinator)',
      image: raz
    },
    {
      name: 'Vanessa Bonde',
      position: 'PRM (Philippines Relationship Manager)',
      image: vanessa
    },
    {
      name: 'Marie Ariessa Lane C. Ko',
      position: 'Dev Team - Lead',
      image: ariessa
    },
    {
      name: 'Alliah Kimberly V. Feliciano',
      position: 'CEC (Community Events Coordinator)',
      image: alliah
    },
    {
      name: 'Teofy Rabanes ',
      position: 'Front-End Developer',
      image: teofy
    },
    {
      name: 'Francis Mathew Gappe',
      position: 'Full-Stack Developer',
      image: francis
    },
    {
      name: 'Marsry Jan Sevilla',
      position: 'UX Designer',
      image: marsry
    }
  ];

  const partners = [
    demiourgos,
    web3,
    ashbury,
    lincoln,
    coolLookOrion,
    nordbite
  ];

  return (
    <Box
      top={matches ? '32vh' : '45vh'}
      minHeight='50px'
      position='absolute'
      right='0px'
      left='0px'
      zIndex={-1}
    >
      <Stack direction='column' alignItems='center' justifyContent='center'>
        <Stack
          spacing={1}
          direction='column'
          alignItems='flex-start'
          justifyContent='start'
          sx={{
            width: matches ? '50%' : '80%'
          }}
        >
          <Typography
            color='white'
            fontSize='18px'
            fontWeight={600}
            sx={{ textShadow: '2px 2px 2px black' }}
          >
            About Fame
          </Typography>
          <Typography
            color='white'
            fontSize='14px'
            sx={{ textShadow: '2px 2px 2px black' }}
          >
            The World’s Web3 Social Media, FAME is a gamified social media
            platform with fully integrated crypto. Creators can build and
            customize any entertainment format they can dream up.
            <br />
            <br />
            Fame is user-friendly and open to everyone. Create media, create
            communities, create revenue, but most of all... create!
            <br />
            <br />
            Fame’s mission is to demystify and democratize{' '}
            <span style={{ color: '#0086FF', fontWeight: 600 }}>defi</span>,
            giving everybody access to the{' '}
            <span style={{ color: '#0086FF', fontWeight: 600 }}>
              benefits of cryptocurrency
            </span>
            .
            <br />
            <br />
            <span style={{ color: '#0086FF', fontWeight: 600 }}>
              Fame’s MVP
            </span>{' '}
            is an interactive dance competition - just one example of how Fame
            can use our social media tools to create and monetize their content.
          </Typography>
          <div
            style={{
              marginTop: matches ? '50px' : '10px'
            }}
          />
          <div className='container'>
            <iframe
              className='responsive-iframe'
              style={{ border: 'none' }}
              width='560'
              height='315'
              src='https://www.youtube.com/embed/Z6Tz7sA_0Uw'
              title='YouTube video player'
              allowFullScreen
            ></iframe>
          </div>

          <Grid
            container
            spacing={1}
            justifyContent='space-between'
            sx={{ paddingTop: matches ? '60px' : '10px' }}
          >
            {executives.map((executive, index) => {
              return (
                <Grid key={index} item xs={4} md={4}>
                  <Stack direction='column' spacing={1} alignItems='center'>
                    <img
                      alt=''
                      src={executive.image}
                      style={{ width: matches ? '200px' : '100px' }}
                    />
                    <Stack direction='column' spacing={0} alignItems='center'>
                      <Typography
                        align='center'
                        color='white'
                        fontSize={matches ? '14px' : '12px'}
                        fontWeight={600}
                      >
                        {executive.name}
                      </Typography>
                      <Typography
                        align='center'
                        color='white'
                        fontSize={matches ? '12px' : '10px'}
                      >
                        {executive.position}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              marginTop: matches ? '50px' : '10px',
              marginBottom: matches ? '50px' : '10px'
            }}
          >
            <Typography
              color='white'
              fontSize='18px'
              fontWeight={600}
              sx={{ textShadow: '2px 2px 2px black' }}
            >
              Team
            </Typography>
          </div>
          <Grid container spacing={1} justifyContent='space-between'>
            {team.map((member, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={4}
                  md={4}
                  sx={{ marginBottom: '5px' }}
                >
                  <Stack direction='column' spacing={1} alignItems='center'>
                    <img
                      alt=''
                      src={member.image}
                      style={{ width: matches ? '200px' : '100px' }}
                    />
                    <Stack direction='column' spacing={0} alignItems='center'>
                      <Typography
                        align='center'
                        color='white'
                        fontSize={matches ? '14px' : '12px'}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        align='center'
                        color='white'
                        fontSize={matches ? '12px' : '10px'}
                      >
                        {member.position}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              marginTop: matches ? '80px' : '30px',
              marginBottom: matches ? '20px' : '30px'
            }}
          >
            <Typography
              color='white'
              fontSize='18px'
              fontWeight={600}
              sx={{ textShadow: '2px 2px 2px black' }}
            >
              Our Partners
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            style={{ marginBottom: '20px' }}
          >
            {partners.map((partner, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={6}
                  md={4}
                  sx={{ marginBottom: '5px' }}
                >
                  <div
                    style={{
                      width: matches ? '200px' : '100px',
                      height: matches ? '200px' : '100px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    <img
                      alt=''
                      src={partner}
                      style={{
                        width: '100%'
                      }}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
      <LandingPageBottomAppbar />
    </Box>
  );
}

export default AboutPage;
