import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks/useGetNetworkConfig';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import {
    Address,
    AddressValue,
    ContractFunction,
    ResultsParser
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js/bignumber.js';
import { smartContract } from './smartContract';

interface IViewMethod {
    method: string;
    args: Array<any>
}

const resultsParser = new ResultsParser();

export const useContractInteractor = () => {
    const { network } = useGetNetworkConfig();

    const { address } = useGetAccount();

    const viewMethod = async ({ method, args = [] }: IViewMethod) => {
        try {
            const query = smartContract.createQuery({
                func: new ContractFunction(method),
                args: args
            });
            const provider = new ProxyNetworkProvider(network.apiAddress);

            const queryResponse = await provider.queryContract(query);

            const endpointDefinition = smartContract.getEndpoint(method);

            const { firstValue } = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            return firstValue?.valueOf();
        } catch (err) {
            console.error('Unable to call ' + method, err);
        }
    };

    const callMethod = async ({ tx }: any) => {
        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: tx,
            transactionsDisplayInfo: {
                processingMessage: 'Processing Ping transaction',
                errorMessage: 'An error has occured during Ping',
                successMessage: 'Ping transaction successful'
            },
            redirectAfterSign: false
        });

        return sessionId;
    };

    const callMethodForPackMint = async ({ tx }: any) => {

        const { sessionId } = await sendTransactions({
            transactions: tx,
            transactionsDisplayInfo: {
                processingMessage: 'Processing Ping transaction',
                errorMessage: 'An error has occured during Ping',
                successMessage: 'Ping transaction successful'
            },
            redirectAfterSign: false
        });

        return sessionId;
    };

    return { viewMethod, callMethod, callMethodForPackMint };
};

export const convertWeiToEgld = async (v: any, decimal = 18, precision = 2) => {
    if (typeof v !== typeof BigNumber) {
        v = new BigNumber(v);
    }
    const factor = Math.pow(10, precision);
    const number = v.div(Math.pow(10, decimal)).toNumber();
    return Math.floor(number * factor) / factor;
};

export const convertEsdtToWei = async (v: number, decimals = 18) => {
    const factor = Math.pow(10, decimals);
    return (new BigNumber(v)).multipliedBy(factor);
};

