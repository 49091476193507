import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const competitionIds = [
  "63aeb5e7f1e17df767195f7e",
  "63aeb5e7f1e17df767195f7f",
  "63aeb5e7f1e17df767195f7a",
  "63aeb5e7f1e17df767195f8a",
];

function BottomAppbar() {
  const { id } = useParams();
  const [newDanceLink, setNewDanceLink] = useState(id);

  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const index = competitionIds.indexOf(id as any);
    if (index > -1) {
      competitionIds.splice(index, 1);
    }
    setNewDanceLink(
      competitionIds[Math.floor(Math.random() * competitionIds.length)]
    );
  }, [id]);

  const handleSpectateButton = () => {
    if (location.pathname === "/spectate") window.location.href = "/demo";
    else navigate(`/spectate/${newDanceLink}`);
  };

  const handleDanceButton = () => navigate("/dance");

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "55px",
        paddingTop: "10px",
        paddingBottom: "3px",
        background: "#FF0000",
      }}
      elevation={3}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ paddingBottom: "10px" }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#000",
            textTransform: "none",
            borderRadius: "15px",
            width: "84px",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#FF0000",
            },
            "&.active": {
              backgroundColor: "#fff",
              color: "#FF0000",
            },
          }}
          onClick={handleSpectateButton}
        >
          Spectate
        </Button>
        <Button
          disabled
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#000",
            textTransform: "none",
            borderRadius: "15px",
            width: "84px",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#FF0000",
            },
            "&:disabled": {
              backgroundColor: "#990000",
              color: "#ff6666",
            },
          }}
          onClick={handleDanceButton}
        >
          Host
        </Button>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "black" }}
      >
        <Link
          href={process.env.PUBLIC_URL + "/fame_whitepaper.pdf"}
          sx={{ color: "white" }}
          target="_blank"
        >
          <Typography
            zIndex={20}
            color="white"
            fontSize={matches ? "14px" : "12px"}
          >
            Download our whitepaper here
          </Typography>
        </Link>
      </Stack>
    </Paper>
  );
}

export default BottomAppbar;
