export const contractAddress =
  'erd1qqqqqqqqqqqqqpgq2wzugtqcpky655mh73mvzw4wsujuchssreyqze2qrr';
export const adminWallet =
  'erd15nzfzrcmahdhhvp2gaq085tzttpta90tpzz6jhpl2y9rxnrxhvps9h9xmz';

export const dAppName = 'Fame';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOKEN_DECIMAL = 18;
export const TOKEN_ISSUE_COST = 0.05;
export const PUBLIC_MINT_PRICE = 0.75;
export const WL_MINT_PRICE = 0.5;
export const PACK_PRICE = 40;
export const VEGLD_TOKEN_ID = 'VEGLD-2b9319';
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
