import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Avatar,
  CardActions,
  Typography
} from '@mui/material';
import fameTokenStill from '../../assets/demo/images/fameTokenStill.png';
import CurrentUserContext from '../../hooks/currentUserContext';
import { track } from '../../utils/Mixpanel';
export default function CompetitionCard({
  id,
  competitionName,
  competitionCoverPhoto,
  hostUsername,
  hostProfilePhoto,
  competitionFameTokens
}: any) {
  const navigate = useNavigate();
  const { fameTokens } = useContext(CurrentUserContext);

  const handleClickSpectate = () => {
    navigate(`/spectate/${id}`);
    track('Spectate');
  };
  const handleClickDance = () => {
    if (fameTokens > competitionFameTokens) navigate(`/dance/${id}`);
    else
    track('Dance');
  };

  return (
    <Box
      display='flex'
      flexDirection='row'
      my={2}
      alignItems='center'
      justifyContent='center'
    >
      <Box mr={2} display='flex' flexDirection='column' justifyContent='center'>
        <Avatar
          alt='hostProfilePhoto'
          sx={{ width: '58px', height: '58px' }}
          src={hostProfilePhoto}
        />
        <Typography color='#D9D9D9' fontSize='12px' fontWeight='600'>
          {`${hostUsername}'s`}
        </Typography>
        <Typography color='#D9D9D9' fontSize='12px' fontWeight='600'>
          {competitionName}
        </Typography>
      </Box>
      <Card
        sx={{
          width: '183px',
          borderRadius: 5,
          height: '119px',
          backgroundColor: '#111111'
        }}
      >
        <CardMedia
          component='img'
          alt='cover-photo'
          height='74px'
          image={competitionCoverPhoto}
        />
        <Box display='flex' justifyContent='flex-end' bgcolor='#111111'>
          <CardActions>
            <Button
              size='small'
              sx={{ color: 'white', textTransform: 'none' }}
              onClick={handleClickSpectate}
            >
              Spectate
            </Button>
            <Button
              size='small'
              variant='contained'
              sx={{
                bgcolor: '#FF0000',
                borderRadius: 5,
                textTransform: 'none',
                ':hover': {
                  bgcolor: '#FF0000'
                }
              }}
              onClick={handleClickDance}
            >
              Dance
            </Button>
          </CardActions>
        </Box>
      </Card>
      <Box ml={2} display='flex' flexDirection='column'>
        <Typography color='white' fontSize={12} style={{ marginBottom: '4px' }}>
          Entry Fee
        </Typography>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box mr={1}>
            <img
              alt='fame-token'
              src={fameTokenStill}
              style={{ width: '100%', maxWidth: '1.5rem' }}
            />
          </Box>
          <Typography color='#D9D9D9' fontSize='12px' fontWeight='600'>
            {competitionFameTokens}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
