import React from 'react'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

function CustomTextfield(props: {
    hasError: boolean
    placeholder: string;
    label: string;
    type: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<unknown>>;
  }) {
   
    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        props.onChange(value);
    };
    
    return (
        <Stack direction='column'>
        <Typography color='white' fontSize='12px' sx={{ marginTop: '12px' }}>
            {props.label}
        </Typography>
        <TextField
            error={props.hasError}
            hiddenLabel
            value={props.value}
            variant='filled'
            size='small'
            placeholder={props.placeholder}
            type={props.type}
            InputProps={{ disableUnderline: true }}
            sx={{
                input: {
                    background: 'white',
                    borderRadius: '15px',
                    fontSize: '12px'
                }
            }}
            inputProps={{
                autoComplete: 'new-email',
            }}
            helperText={props.hasError ? "Please use a valid email address" : ""}
            onChange={handleTextChange}
            fullWidth
            required
        />
        </Stack>
    );
           
            
}

export default CustomTextfield