function getRandomVideos(numOfVids: number, videos: any[]) {
  let newArray = videos.slice();
  const randomVideos = [];

  while (randomVideos.length < numOfVids) {
    const randomIndex = Math.floor(Math.random() * newArray.length);
    const pair = { eliminated: false };
    randomVideos.push({ ...newArray[randomIndex], ...pair });
    newArray[randomIndex] = "";
    newArray = newArray.filter((video) => {
      return video;
    });
  }
  return randomVideos;
}

export default getRandomVideos;
