import React from 'react';
import Button from '@mui/material/Button/';

function ImageButton(props: any) {
  return (
    <Button
      id={props.id}
      onClick={props.onClick}
      sx={{
        backgroundImage: `url(${props.src})`,
        '&:hover': {
          backgroundImage: `url(${props.mouseOver})`,
          backgroundColor: 'transparent'
        },
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: props.width,
        height: props.height
      }}
      type={props.type}
    />
  );
}

export default ImageButton;
