import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import homeButton from '../../assets/landing-pages/homeButton.png';
import homeButtonOver from '../../assets/landing-pages/homeButtonOver.png';
import profileButton from '../../assets/landing-pages/profileButton.png';
import profileButtonOver from '../../assets/landing-pages/profileButtonOver.png';
import { isLoggedIn } from '../../utils/auth';

function HomeButton(props: { page: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const matches = useMediaQuery('(min-width:600px)');

  const MenuButton = (menuButtonProps: {
    id: string;
    nav: string;
    icon: string;
    iconHover: string;
  }) => {
    return (
      <Button
        id={menuButtonProps.id}
        onClick={() => {
          navigate(menuButtonProps.nav);
        }}
        sx={{
          backgroundImage: `url(${menuButtonProps.icon})`,
          '&:hover': {
            backgroundImage: `url(${menuButtonProps.iconHover})`,
            backgroundColor: 'transparent'
          },
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: '30px',
          width: '30px'
        }}
      />
    );
  };

  return (
    <div
      style={
        matches
          ? { top: 25, left: 25, position: 'fixed', zIndex: 5 }
          : {
              marginLeft: '30px',
              marginTop: '20px',
              position: props.page === 'landing-pages' ? 'fixed' : 'relative',
              zIndex: 5
            }
      }
    >
      <Stack direction='row' spacing={-2}>
        <MenuButton
          id='home-button'
          nav='/'
          icon={homeButton}
          iconHover={homeButtonOver}
        />
        <MenuButton
          id='profile-button'
          nav={
            isLoggedIn()
              ? `/profile`
              : '/login'
          }
          icon={profileButton}
          iconHover={profileButtonOver}
        />
        {location.pathname !== `/profile` && isLoggedIn() && (
          <Button
            onClick={() => {
              localStorage.clear();
              navigate('/login');
            }}
            sx={{
              backgroundColor: '#FF0000',
              color: '#fff',
              textTransform: 'none',
              borderRadius: '15px',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#FF0000'
              },
              '&.active': {
                backgroundColor: '#fff',
                color: '#FF0000'
              }
            }}
          >
            Sign Out
          </Button>
        )}
      </Stack>
    </div>
  );
}

export default HomeButton;
