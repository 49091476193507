import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DialogContent, IconButton, Typography } from "@mui/material";
import logo from "../../assets/fame-club/fameClubLogoBlack.png"

function FameClubSignUpConfirmationModal({ isOpen, handleClose }: any) {
  const matches = useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();
  
  const handleConfirm = () => {
    navigate("/create-profile")
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            backgroundColor: "white",
            border: "solid 3px red",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            {handleClose ? (
              <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Stack direction='column'>
              <img
                alt='fame-club-logo'
                src={logo}
                style={{
                  width: matches ? '30%' : '50%',
                  height: matches ? '30%' : '50%',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  paddingBottom: "3%"
                }}
              />
              <Typography
                align="center"
                fontWeight="bold"
                fontSize={matches ? 24 : 18}
                letterSpacing="-1px"
                lineHeight={matches ? "30px" : "20px"}
                paddingX={matches ? "15%" : 0}
                paddingY="20px"
              >
                You have successfully signed up for Fame Club Waitlist
              </Typography>
              <Typography
                align="center"
                fontWeight={600}
                fontSize={matches ? 16 : 14}
                letterSpacing="-1px"
                lineHeight={matches ? "20px" : "15px"}
                paddingX={matches ? "11%" : 0}>
                To get earlier access to Fame Club, you need to have a Fame Profile.
                Would you like to Create a Fame Profile now?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Button
                size={matches ? "large" : "small"}
                sx={{ color: "black", textTransform: "none" }}
                onClick={handleClose} // to add onclick
              >
                Maybe Later
              </Button>
              <Button
                size={matches ? "large" : "small"}
                variant="contained"
                sx={{
                  bgcolor: "#FF0000",
                  borderRadius: 2,
                  textTransform: "none",
                  ":hover": {
                    bgcolor: "#FF0000",
                  },
                  fontWeight: "bold"
                }}
                onClick={handleConfirm}
              >
                Create Fame Profile
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default FameClubSignUpConfirmationModal;
