import React, { useRef } from "react";
import { Box, Typography, Backdrop, Avatar, Stack } from "@mui/material";
import { Fireworks } from "@fireworks-js/react";
import fameToken from "../../assets/demo/gif/fameToken.gif";
import fameStar from "../../assets/demo/gif/fameStar.gif";

function YouWonDisplay(props: any) {
  const ref = useRef(null);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
      }}
      open={props.open}
    >
      <div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            alt="Remy Sharp"
            src={props.avatar}
            sx={{
              width: 100,
              height: 100,
              border: "solid 2px red",
              mb: 2,
            }}
          />
          <Typography color="#D9D9D9" fontSize="48px" fontWeight="600">
            You won!
          </Typography>
          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={1}>
              <img
                alt="fame-star"
                src={fameToken}
                style={{ width: "100%", maxWidth: "2rem" }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                {props.fameToken}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <img
                alt="fame-star"
                src={fameStar}
                style={{ width: "100%", maxWidth: "2rem" }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                {props.fameXP}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Fireworks
          ref={ref}
          options={{ opacity: 1 }}
          style={{
            left: 0,
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />
      </div>
    </Backdrop>
  );
}

export default YouWonDisplay;
