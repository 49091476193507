import React from 'react'
import { Navigate } from 'react-router-dom'
import { isLoggedIn } from 'utils/auth'

function PrivateRoute({ children} : any) {
    if (!isLoggedIn()) {
        return <Navigate to="/login" replace />
    }
  return children
}

export default PrivateRoute