import React from "react";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import fameTokenStill from "../../assets/demo/images/fameTokenStill.png";
import fameStarStill from "../../assets/demo/images/fameXPStill.png";

function DanceCompetitionTitle({ title, token, xp }: any) {
  const matches = useMediaQuery("(min-width:600px)");

  const iconSizes = matches ? "1.5rem" : "1rem";

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      spacing={0}
      bgcolor="#111111"
      borderRadius={matches ? "15px 15px 0px 0px" : "0px"}
      padding="5px"
    >
      <Typography
        sx={{
          color: "#fff",
          textAlign: "center",
          pl: "15px",
          fontSize: matches ? "16px" : "12px",
        }}
      >
        {title}
      </Typography>
      <Stack direction="row" spacing={1} sx={{ pr: "15px" }}>
        <Stack direction="row" spacing={1}>
          <img
            alt="fame-token"
            src={fameTokenStill}
            style={{
              width: "100%",
              maxWidth: iconSizes,
              maxHeight: iconSizes,
            }}
          />
          <Typography
            sx={{
              fontSize: matches ? "16px" : "12px",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {token}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <img
            alt="fame-XP"
            src={fameStarStill}
            style={{
              width: "100%",
              maxWidth: iconSizes,
              maxHeight: iconSizes,
            }}
          />
          <Typography
            sx={{
              fontSize: matches ? "16px" : "12px",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {xp}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default DanceCompetitionTitle;
