import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import WalletConnect from 'components/WalletConnect';
import HomeButton from 'components/buttons/HomeButton';
import mobileBG from '../../assets/demo/images/mobileBG.png';
import desktopBG from '../../assets/demo/images/desktopBG.png';
import fameLogo from '../../assets/demo/images/logo.png';
import fameToken from '../../assets/demo/gif/fameToken.gif';
import fameStar from '../../assets/demo/gif/fameStar.gif';
import fameTokenStill from '../../assets/demo/images/fameTokenStill.png';
import fameStarStill from '../../assets/demo/images/fameXPStill.png';
import jackpotCoins from '../../assets/demo/gif/jackpotCoins.gif';
import tokensIn from '../../assets/demo/audio/userPayout.mp3';
import tokensOut from '../../assets/demo/audio/userPayin.mp3';
import CurrentUserContext from '../../hooks/currentUserContext';
import { slowConnectionTypes } from '../../utils/constants/connectionTypes';

const counterAnimation = (
  qSelector: any,
  start = 0,
  end: any,
  duration = 1000
) => {
  const target = document.querySelector(qSelector);
  let startTimestamp: any = null;
  const step = (timestamp: any) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    target.innerText = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

let level = 0;

function Header() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    jackpotPoints,
    fameTokens,
    fameXP,
    fameLevel,
    username,
    userProfilePhoto,
    isWinner,
    setCurrentUser,
    ...currentUser
  }: any = useContext(CurrentUserContext);

  const matches = useMediaQuery('(min-width:600px)');

  const notHomePage =
    (location.pathname === `/spectate/${id}` && matches) ||
    (location.pathname === `/dance/${id}` && matches);

  const bg = matches ? `url(${desktopBG})` : `url(${mobileBG})`;

  const [userDetails, setUserDetails] = useState<any>({});
  const [competitionDetails, setCompetitionDetails] = useState<any>({});
  const [tokenAnimate, setTokenAnimate] = useState(false);
  const [XPAnimate, setXPAnimate] = useState(false);

  const connectionType =
    (window?.navigator as any)?.connection?.effectiveType || '';
  const isSlowConnection = slowConnectionTypes.includes(connectionType);

  //initial user data when entering the competition
  const fetchCompetition = async () => {
    if (isSlowConnection) {
      return;
    }

    const fetchedCompetitions = await fetch(
      'https://fame.cool/competitions'
    );

    //fetch competition details
    const competitions = await fetchedCompetitions.json();
    const details =
      id && competitions.find((competition: any) => (competition.id = id));

    //subtract details for updating user points
    const updatedTokenPoints = fameTokens - details.fameTokens;
    const updatedJackpotPoints = jackpotPoints + details.fameTokens * 4;

    setCompetitionDetails(details);

    //for updating current user not globally
    setUserDetails({
      jackpotPoints: updatedJackpotPoints,
      fameTokens: updatedTokenPoints,
      fameXP: fameXP,
      fameLevel: fameLevel
    });

    //for updating top bar details
    setCurrentUser({
      ...currentUser,
      username,
      userProfilePhoto,
      jackpotPoints: updatedJackpotPoints,
      fameTokens: updatedTokenPoints,
      fameXP: fameXP,
      fameLevel: fameLevel,
      isWinner: false
    });

    //add animations
    counterAnimation('#fame-tokens', fameTokens, updatedTokenPoints, 1000);
    counterAnimation(
      '#fame-jackpot',
      jackpotPoints,
      updatedJackpotPoints,
      1000
    );

    setTokenAnimate(true);
    setTimeout(() => {
      setTokenAnimate(false);
    }, 3000);

    //add sound effects
    (document.getElementById('user-payin') as any).play();
  };

  const detailsAfterWinning = () => {
    let isLevelUp = false;
    //add tokens
    const updatedTokenPoints =
      userDetails.fameTokens + competitionDetails.fameTokens * 4;
    const updatedFameXP = userDetails.fameXP + competitionDetails.fameXP;

    //add animations
    counterAnimation(
      '#fame-tokens',
      userDetails.fameTokens,
      updatedTokenPoints,
      6000
    );
    counterAnimation('#fame-XP', userDetails.fameXP, updatedFameXP, 3000);
    setTokenAnimate(true);
    setXPAnimate(true);
    setTimeout(() => {
      setTokenAnimate(false);
      setXPAnimate(false);
    }, 5000);

    //check the levels
    if (updatedFameXP >= 3800 && level === 0) {
      isLevelUp = true;
      level += 1;
    } else if (updatedFameXP >= 3900 && level === 1) {
      isLevelUp = true;
      level += 1;
    } else if (updatedFameXP >= 4000 && level === 2) {
      isLevelUp = true;
      level += 1;
    } else if (updatedFameXP >= 4100 && level === 3) {
      isLevelUp = true;
      level += 1;
    } else if (updatedFameXP >= 4200 && level === 4) {
      isLevelUp = true;
      level += 1;
    } else if (updatedFameXP > 4300 && level > 4) {
      isLevelUp = true;
      level += 1;
      console.log('the logic ends here');
    }

    const updatedLevel = isLevelUp
      ? userDetails.fameLevel + 1
      : userDetails.fameLevel;

    setCurrentUser({
      ...currentUser,
      username,
      userProfilePhoto,
      jackpotPoints: userDetails.jackpotPoints,
      fameTokens: updatedTokenPoints,
      fameXP: updatedFameXP,
      fameLevel: updatedLevel,
      isWinner: false,
      levelUp: isLevelUp
    });

    //add sound effects
    (document.getElementById('user-win') as any).play();
  };

  useEffect(() => {
    if (
      location.pathname === '/spectate' ||
      location.pathname === `/dance/${id}`
    ) {
      fetchCompetition();
    }
  }, [id]);

  useEffect(() => {
    if (isWinner) detailsAfterWinning();
  }, [isWinner]);

  return (
    <>
      <WalletConnect />
      <audio id='user-payin' preload='metadata' style={{ display: 'hidden' }}>
        <source src={tokensOut} type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio>
      <audio id='user-win' preload='metadata' style={{ display: 'hidden' }}>
        <source src={tokensIn} type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio>
      <Paper
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          backgroundImage: matches
            ? `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0)  60%), ${bg}`
            : `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0)  20%), ${bg}`,
          backgroundSize: 'cover',
          borderRadius: '0px'
        }}
        elevation={3}
      >
        <HomeButton page='demo' />
        {/* ) : (
          <div />
        )} */}

        <Grid
          container
          spacing={{ xs: 2 }}
          columns={{ xs: 9 }}
          direction='row'
          alignItems='center'
          sx={{ paddingTop: '10px', paddingX: '20px' }}
        >
          <Grid
            item
            justifyContent='center'
            xs={2}
            sx={{ position: 'relative' }}
          >
            {notHomePage ? (
              <>
                <Stack
                  direction='column'
                  alignItems='center'
                  spacing={0.5}
                  sx={{
                    paddingTop: '10px',
                    paddingBottom: matches ? '40px' : '0vh'
                  }}
                >
                  <button
                    id='see-nft-button'
                    onClick={() => {
                      navigate('/');
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      alt='fame-logo'
                      src={fameLogo}
                      style={{
                        width: '100%',
                        maxWidth: '10rem'
                      }}
                    />
                  </button>
                  <Stack
                    direction='column'
                    alignItems='center'
                    spacing={0.5}
                    sx={{
                      paddingTop: '10px',
                      paddingBottom: matches ? '40px' : '2vh'
                    }}
                  >
                    <img
                      alt='jackpot-coins'
                      src={jackpotCoins}
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%',
                        maxWidth: matches ? '5rem' : '3rem'
                      }}
                    />
                    <Typography
                      id='fame-jackpot'
                      sx={{
                        fontSize: matches ? '16px' : '12px',
                        fontWeight: '600',
                        color: '#fff'
                      }}
                    >
                      {jackpotPoints}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                <Stack
                  direction='column'
                  alignItems='center'
                  spacing={0.5}
                  sx={{
                    paddingBottom: matches ? '40px' : '3rem'
                  }}
                >
                  <img
                    alt='jackpot-coins'
                    src={jackpotCoins}
                    style={{
                      position: 'absolute',
                      top: '4vh',
                      left: '13vw',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      maxWidth: matches ? '6rem' : '4rem'
                    }}
                  />
                  <Typography
                    id='fame-jackpot'
                    sx={{
                      position: 'absolute',
                      top: matches ? '10vh' : '8vh',
                      fontSize: matches ? '16px' : '12px',
                      fontWeight: '600',
                      color: '#fff'
                    }}
                  >
                    {jackpotPoints}
                  </Typography>
                </Stack>
              </>
            )}
          </Grid>

          <Grid item xs={5}>
            {notHomePage ? (
              <div />
            ) : (
              <img
                onClick={() => {
                  navigate('/');
                }}
                alt='fame-logo'
                src={fameLogo}
                style={
                  matches
                    ? {
                        width: '100%',
                        maxWidth: '230px',
                        paddingTop: '2rem',
                        paddingBottom: '4rem',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        cursor: 'pointer'
                      }
                    : {
                        width: '100%'
                      }
                }
              />
            )}
          </Grid>
          <Grid item xs={2}>
            {/* <Stack
              direction="column"
              alignItems="center"
              sx={{
                paddingTop: "10px",
                paddingBottom: matches ? "40px" : "2vh",
                paddingRight: "20px",
              }}
            >
              <IconButton onClick={handleAvatarClick}>
                <Avatar
                  alt="avatar"
                  src={userProfilePhoto}
                  sx={{
                    width: 45,
                    height: 45,
                    border: "solid 2px red",
                  }}
                />
              </IconButton>

              <Typography
                sx={{
                  fontSize: matches ? "16px" : "12px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                {username}
              </Typography>
            </Stack> */}
            <Stack
              direction='column'
              alignItems='center'
              spacing={0.5}
              sx={{ paddingBottom: '10px', paddingRight: '20px' }}
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                sx={{ position: 'relative' }}
              >
                {tokenAnimate ? (
                  <img
                    alt='fame-token'
                    src={fameToken}
                    style={{
                      width: '100%',
                      maxWidth: matches ? '2rem ' : '1.5rem'
                    }}
                  />
                ) : (
                  <img
                    alt='fame-token-still'
                    src={fameTokenStill}
                    style={{
                      width: '100%',
                      maxWidth: matches ? '2rem ' : '1.5rem'
                    }}
                  />
                )}

                <Typography
                  id='fame-tokens'
                  sx={{
                    fontSize: matches ? '16px' : '12px',
                    fontWeight: '600',
                    color: '#fff'
                  }}
                >
                  {fameTokens}
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                {XPAnimate ? (
                  <img
                    alt='fame-star'
                    src={fameStar}
                    style={{
                      width: '100%',
                      maxWidth: matches ? '2rem ' : '1.5rem'
                    }}
                  />
                ) : (
                  <img
                    alt='fame-star-still'
                    src={fameStarStill}
                    style={{
                      width: '100%',
                      maxWidth: matches ? '2rem ' : '1.5rem'
                    }}
                  />
                )}

                <Typography
                  id='fame-XP'
                  sx={{
                    fontSize: matches ? '16px' : '12px',
                    fontWeight: '600',
                    color: '#fff'
                  }}
                >
                  {fameXP}
                </Typography>
              </Stack>

              <Stack direction='row' alignItems='center' spacing={1}>
                <img
                  alt='fame-token'
                  src={fameLogo}
                  style={{
                    width: '100%',
                    maxWidth: matches ? '2rem ' : '1.5rem'
                  }}
                />
                <Typography
                  id='fame-level'
                  sx={{
                    fontSize: matches ? '16px' : '12px',
                    fontWeight: '600',
                    color: '#fff'
                  }}
                >
                  {fameLevel}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Header;
