import './index.css';
import './assets/sass/theme.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { App } from './App';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <App>
                <Helmet>
                    <title>Fame</title>
                    <link rel="canonical" href="https://www.fame.cool" />
                    <meta name="description" content="The World's Web3 Social Media. Come Play. Dance, Sing, Game, and earn money for sharing your passion. Crypto and NFTs with real-world utility. Fame is where you find community and meet new friends. Welcome to the Fame Fam! Crypto și NFT-uri. Crypto et NFT." />
                    <meta name="keywords" content="Non-fungible token, NFT marketplace, NFT art, NFT collectibles, NFT blockchain, NFT cryptocurrency, NFT digital asset, NFT tokenization, NFT ownership, NFT certification, Dance Spectator, Dance, Dance contest, Spectator, Spectate dance, NFT, Fame, NFT token, Crypto, Dance application, Earn, Fame dance contest" />
                    <meta name="robots" content="index, follow" />
                </Helmet>
            </App>
        </HelmetProvider>
    </React.StrictMode>
);
