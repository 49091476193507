import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Stack, Button } from '@mui/material';
import fameClubLogo from '../../assets/fame-club/fameClubLogo.png'
import comingSoonIcon from '../../assets/fame-club/comingSoonIcon.webp'
import comingSoonBG from '../../assets/fame-club/comingSoonHeader.jpg'
import homeButton from '../../assets/landing-pages/homeButton.png';
import homeButtonOver from '../../assets/landing-pages/homeButtonOver.png';

function ComingSoonPageTopBar() {
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <div style={{ top: 25, left: 25, position: 'fixed', zIndex: 5 }}>
          <Button
            id='homes-button'
            onClick={() => {
              navigate('/');
            }}
            sx={{
              backgroundImage: `url(${homeButton})`,
              '&:hover': {
                backgroundImage: `url(${homeButtonOver})`,
                backgroundColor: 'transparent'
              },
              backgroundColor: 'transparent',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '30px',
              width: '30px'
            }}
          />
      </div>
      <Paper
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          backgroundColor: "black",  
          backgroundImage: `url(${comingSoonBG})`,
          backgroundSize: 'cover',
          borderRadius: '0px',
          zIndex: matches ? -2 : 0
        }}
        elevation={3}
      >
        <Stack
          direction='column'
          alignItems='center'
        >
        <Stack direction="row" 
          spacing={1} 
          justifyContent="center"
          alignItems="center"
          sx={{paddingY: "5%", paddingX: matches ? "0px" : "15vw"}}>
            <img
            alt='coming-soon-icon'
            src={comingSoonIcon}
            style={{
              width: matches ? '30%' : '40%',
              height: matches ? '30%' : '40%',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
           />
           <img
            alt='fame-club-logo'
            src={fameClubLogo}
            style={{
              width: matches ? '45%' : '40%',
              height: matches ? '45%' : '40%',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
           />
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

export default ComingSoonPageTopBar;
