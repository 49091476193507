/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

const currentUserContext = createContext({
  id: '',
  email: '',
  username: '',
  fameTokens: 0,
  fameXP: 0,
  fameLevel: 0,
  jackpotPoints: 0,
  isWinner: false,
  levelUp: false,
  userProfilePhoto: "",
  setCurrentUser: function() {
    //placeholder
  },
  emailSent: false
});

export default currentUserContext;
