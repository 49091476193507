import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppState } from "react-native";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import VideoFrameDiv from "../components/demo/VideoFrameDiv";
import CountdownTimer from "../components/demo/CountdownTimer";
import DanceCompetitionTitle from "../components/demo/DanceCompetitionTitle";
import LoadingScreen from "../components/demo/LoadingScreen";
import getRandomVideos from "../utils/getRandomVideos";
import bgMusic from "../assets/demo/audio/music.mp3";
import eliminated from "../assets/demo/audio/eliminated.mp3";
import countdownSound from "../assets/demo/audio/countdown-sound.mp3";
import winnerSound from "../assets/demo/audio/winner.mp3";
import SpectateAgainModal from "../components/modals/SpectateAgainModal";
import { slowConnectionTypes } from "../utils/constants/connectionTypes";

const containerDesktop = {
  width: "60%",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const containerMobile = {
  overflow: "auto",
  top: "12em",
  bottom: "1.4em",
  left: "0px",
  right: "0px",
  position: "fixed",
  bgColor: "black",
  zIndex: -2,
};

function DanceSpectatorPage() {
  const matches = useMediaQuery("(min-width:600px)");

  const [dbVideosData, setDbVideosData] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const [openCountdown, setOpenCountdown] = useState(true);
  const [addCountdown, setAddCountdown] = useState(false);
  const [spectateAgainModal, setSpectateAgainModal] = useState(false);

  const [loadCount, setLoadCount] = useState(0);

  const connectionType = (window?.navigator as any)?.connection?.effectiveType || "";
  const isSlowConnection = slowConnectionTypes.includes(connectionType);

  const appState = useRef(AppState.currentState);
  const navigate = useNavigate();

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      appState.current = nextAppState;
      if (appState.current.match(/inactive|background/)) {
        navigate("/demo");
      }
    });

    return () => {
      subscription.remove();
    };
  }, [navigate]);

  const setVideoLoaded = () => {
    if (!isSlowConnection) {
      if (loadCount < 3) {
        setLoadCount(loadCount + 1);
      } else {
        setAddCountdown(true);
        (document.getElementById("countdown-sound") as any).play();
      }
    }
  };

  const playVideo = () => {
    for (let i = 0; i < 4; i++) {
      const videoToPlay: any = document.getElementById(`contestant-${i}`);
      const videoPlay = videoToPlay.play();
      videoPlay.catch(() => {
        videoPlay.play();
      });
    }

    (document.getElementById("bg-music") as any).play();
  };

  if ((performance.getEntriesByType("navigation")[0] as any).type === "reload") {
    window.location.href = "/demo";
    console.info("This page is reloaded");
  }

  useEffect(() => {
    if (!isSlowConnection) {
      fetch("https://fame.cool/videos")
        .then((response) => response.json())
        .then((data) => setDbVideosData(data));
    }
  }, []);

  useEffect(() => {
    if (dbVideosData.length !== 0) {
      const randomVideos = getRandomVideos(4, dbVideosData);
      setVideos(randomVideos);
    }
  }, [dbVideosData]);

  useEffect(() => {
    if (isSlowConnection) {
      return;
    }
    if (addCountdown) {
      setTimeout(() => {
        if (videos.length > 0) {
          setOpenCountdown(false);
          playVideo();
        }
      }, 10000);
    }
  }, [addCountdown]);

  const pauseVideo = (videoID: any, effectID: any) => {
    const video: any = document.getElementById(videoID);
    const effect: any = document.getElementById(effectID);

    if (video !== null || effect !== null) {
      video.classList.add("competitor-eliminated");
      video.pause();
      // effect.style.visibility = "visible";

      (document.getElementById("eliminated-sound") as any).play();
    }
  };

  const playWinner = (videoID: any) => {
    const video = document.getElementById(videoID);
    if (video !== null) {
      if (!video.classList.contains("competitor-eliminated")) {
        const effect: any = document.getElementById("winner-effect-3");
        effect.style.visibility = "visible";

        (document.getElementById("winner-music") as any).play();
        (document.getElementById("eliminated-sound") as any).pause();
      }
    }
  };

  useEffect(() => {
    if (isSlowConnection) {
      return;
    }
    if (!openCountdown) {
      // setTimeout(() => {
      //   pauseVideo("contestant-0", "effect-0");
      // }, 10000);

      // setTimeout(() => {
      //   pauseVideo("contestant-1", "effect-1");
      // }, 15000);

      // setTimeout(() => {
      //   pauseVideo("contestant-2", "effect-2");
      // }, 20000);

      setTimeout(() => {
        pauseVideo("contestant-0", "effect-0");
        pauseVideo("contestant-1", "effect-1");
        pauseVideo("contestant-2", "effect-2");
        playWinner("contestant-3");
      }, 20000);
      setTimeout(() => {
        setSpectateAgainModal(true);
      }, 25000);
    }
  }, [openCountdown]);

  return (
    <>
      {isSlowConnection ? (
        <LoadingScreen
          loading={true}
          screenMessage="Ooops!"
          isSlowConnection={isSlowConnection}
          isOpen={isSlowConnection}
        />
      ) : (
        <>
          {spectateAgainModal ? <SpectateAgainModal open={true} /> : <div />}
          <audio
            id="countdown-sound"
            preload="metadata"
            style={{ display: "none" }}
          >
            <source src={countdownSound} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <audio
            id="eliminated-sound"
            preload="metadata"
            style={{ display: "hidden" }}
          >
            <source src={eliminated} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <audio
            id="winner-music"
            preload="metadata"
            style={{ display: "hidden" }}
          >
            <source src={winnerSound} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          {!addCountdown ? (
            <LoadingScreen
              loading={!addCountdown}
              screenMessage="Waiting for other contestants..."
              isSlowConnection={isSlowConnection}
              isOpen={!addCountdown }
            />
          ) : (
            <CountdownTimer open={openCountdown} />
          )}
          {!matches && (
            <Box
              top={matches ? "17em" : "9em"}
              width={matches ? "50%" : "100%"}
              position="absolute"
              right="0px"
              left="0px"
              bgcolor="black"
              zIndex={-1}
            >
              <DanceCompetitionTitle
                title="Alice's Dance Party"
                token={11}
                xp={31}
              />
            </Box>
          )}
          <Box sx={matches ? containerDesktop : containerMobile}>
            {matches && (
              <DanceCompetitionTitle
                title="Alice's Dance Party"
                token={11}
                xp={31}
              />
            )}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              alignItems="stretch"
            >
              <audio
                id="bg-music"
                preload="metadata"
                style={{ display: "hidden" }}
              >
                <source src={bgMusic} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              {videos.map((video: any, index: any) => {
                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={video.id}>
                    <VideoFrameDiv
                      index={index}
                      video={
                        <div className="video-spectator">
                          <>
                            <audio
                              src={bgMusic}
                              style={{ display: "hidden" }}
                            />
                            <video
                              id={`contestant-${index}`}
                              className="dance-contestants"
                              height="100%"
                              width="100%"
                              src={video.urlLink}
                              // type="video/mp4"
                              onLoadedMetadata={() => {
                                setVideoLoaded();
                              }}
                              preload="metadata"
                              muted
                              playsInline
                            ></video>
                          </>
                        </div>
                      }
                      name={video.username}
                      profilePic={video.userProfilePhoto}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default DanceSpectatorPage;
