import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Stack, Typography } from '@mui/material';
import NFTCollectionTitle from '../../assets/nft-page/nft-collection.png';
import NFTCollectionFlowchart from '../../assets/nft-page/nft-collection-flowchart.png';
import section1Bg from '../../assets/nft-page/section-1-bg.png';
import iceCloutBadgeNoGlow from '../../assets/nft-page/ice-clout-badge-no-glow.webp';
import iceCloutBadgeWithGlow from '../../assets/nft-page/ice-clout-badge-with-glow.webp';
import starBullet from '../../assets/nft-page/star-bullet.png';

function NFTCollectionSection() {
  const matches = useMediaQuery('(min-width:600px)');

  const fontSize = matches ? '14px' : '12px';

  const NFTPageTypography = (props: any) => {
    return (
      <Stack
        direction='row'
        alignItems='center'
        sx={{ paddingLeft: '25px', paddingRight: '25px' }}
      >
        {props.bullet ? (
          <img
            alt='star-bullet'
            src={starBullet}
            style={{ width: '10px', height: '10px', marginRight: '5px' }}
          />
        ) : (
          <div />
        )}
        {props.children}
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${section1Bg})`,
        backgroundColor: 'black',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '0px',
        marginBottom: '70px'
      }}
      elevation={3}
    >
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <Stack
          direction='row'
          alignContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          spacing={2}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '70px'
          }}
        >
          <img
            alt='nft-collection'
            src={NFTCollectionTitle}
            style={{ width: matches ? '30%' : '50%' }}
          />
        </Stack>
        <div style={{ marginTop: '10px' }} />
        <NFTPageTypography>
          <Typography color='white' fontSize={fontSize} fontWeight={600}>
            Utilities and Rewards
          </Typography>
        </NFTPageTypography>
        <div style={{ marginTop: '20px' }} />
        <NFTPageTypography>
          <Typography
            color='white'
            fontSize={fontSize}
            fontWeight={400}
            align='center'
          >
            There are 15,000 Fame NFTs available, divided into 5 different
            designs.
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography color='white' fontSize={fontSize} fontWeight={400}>
            All Fame NFT owners receive an ICE Clout Badge
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography
            color='white'
            fontSize={fontSize}
            fontWeight={400}
            align='center'
          >
            ({"Fame's version of the "}
            <span style={{ color: '#0086FF', fontStyle: 'italic' }}>
              {'Blue Checkmark'}
            </span>
            )
          </Typography>
        </NFTPageTypography>

        <Stack direction='column' alignItems='center'>
          <img
            alt='nft-collection-flowchart'
            src={NFTCollectionFlowchart}
            style={{ width: matches ? '50%' : '60%', marginTop: '10px' }}
          />
          <div style={{ width: '280px' }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              style={{
                margin: '10px 0px 10px'
              }}
            >
              <img
                alt='ice-clout-badge-no-glow'
                src={iceCloutBadgeNoGlow}
                style={{ width: '100px' }}
              />
              <img
                alt='ice-clout-badge-with-glow'
                src={iceCloutBadgeWithGlow}
                style={{ width: '100px' }}
              />
            </Stack>
          </div>
        </Stack>

        <NFTPageTypography>
          <Typography color='white' fontSize={fontSize} fontWeight={400}>
            Owning 1 + NFTs ={' '}
            <span style={{ color: '#0086FF', fontWeight: 600 }}>
              ICE Clout Badge
            </span>
          </Typography>
        </NFTPageTypography>
        <NFTPageTypography>
          <Typography
            align='center'
            color='white'
            fontSize={fontSize}
            fontWeight={400}
          >
            Owning 1 + full set of NFTs = rare{' '}
            <span style={{ color: '#0086FF', fontWeight: 600 }}>
              Animated ICE Clout Badge
            </span>
          </Typography>
        </NFTPageTypography>

        <Stack
          direction='column'
          alignItems='center'
          style={{ marginTop: '40px' }}
        >
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Discount on most{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>merch</span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Discount on future{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>NFTs</span>{' '}
              and sales
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Chance to receive a{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>
                BAD bonus
              </span>
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography
              align='center'
              color='white'
              fontSize={fontSize}
              fontWeight={400}
            >
              Competition entry for{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>
                Lunch Date
              </span>{' '}
              with your favorite creator
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography
              align='center'
              color='white'
              fontSize={fontSize}
              fontWeight={400}
            >
              (Full Set Only) Blooming feature (chance to receive{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>
                2000 FAME/day
              </span>{' '}
              )
            </Typography>
          </NFTPageTypography>
          <NFTPageTypography bullet={true}>
            <Typography color='white' fontSize={fontSize} fontWeight={400}>
              Future benefits and{' '}
              <span style={{ color: '#0086FF', fontWeight: 600 }}>perks</span>
            </Typography>
          </NFTPageTypography>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default NFTCollectionSection;
