import React from 'react'
import Stack from "@mui/material/Stack"
import useMediaQuery from '@mui/material/useMediaQuery'
import DownloadLink from './DownloadLink';

function FameLogoDownload({ className, altText, imageFilePNG, imageFileSVG, fileNamePNG, fileNameSVG }: any) {
    const matches = useMediaQuery("(min-width:600px)");

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            paddingBottom={matches ? "0px" : "20px"}
        >
            <div className={className}>
                <img
                    alt={altText}
                    src={imageFilePNG}
                    style={{ width: matches ? "70%" : "50%", position: 'relative' }}
                />
            </div>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center">
                <DownloadLink logo={imageFileSVG} fileName={fileNameSVG} buttonText=".svg" />
                <DownloadLink logo={imageFilePNG} fileName={fileNamePNG} buttonText=".png" />
            </Stack>
        </Stack>
    )
}

export default FameLogoDownload