import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import {
  DappProvider,
  AxiosInterceptorContext // using this is optional
} from '@multiversx/sdk-dapp/wrappers';

import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { routeNames } from 'routes';
import { routes } from 'routes';
import { PageNotFound, Unlock } from 'pages';
import {
  apiTimeout,
  walletConnectV2ProjectId,
  sampleAuthenticatedDomains
} from 'config';
import { Layout } from 'components/Layout';
import CurrentUserContext from './hooks/currentUserContext';

declare global {
  interface Window {
    localStream: any;
  }
}

export const App = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    fetch("https://fame.cool/current-user")
      .then((response) => response.json())
      .then((data) =>
        setCurrentUser({
          ...data,
          jackpotPoints: 500,
          isWinner: false,
          levelUp: false
        })
      );
  }, []);

  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={sampleAuthenticatedDomains}
      >
        <Router>
          <DappProvider
            environment={EnvironmentsEnum.mainnet}
            customNetworkConfig={{
              name: 'customConfig',
              apiTimeout,
              walletConnectV2ProjectId
            }}
          >
            <Layout>
              <AxiosInterceptorContext.Listener />
              <TransactionsToastList />
              <NotificationModal />
              <SignTransactionsModals className='custom-class-for-modals' />
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              >
                <CurrentUserContext.Provider
                  value={{ ...currentUser, setCurrentUser }}
                >
                  <Routes>
                    <Route path={routeNames.unlock} element={<Unlock />} />
                    {routes.map((route, index) => 
                        
                        <Route
                          path={route.path}
                          key={'route-key-' + index}
                          element={route.component}
                        />
                       
                    )}
                    <Route path='*' element={<PageNotFound />} />
                  </Routes>
                </CurrentUserContext.Provider>
              </GoogleOAuthProvider>
            </Layout>
          </DappProvider>
        </Router>
        <ToastContainer autoClose={3000} hideProgressBar={true} />
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};
