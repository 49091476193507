import React from 'react';
import { Stack } from '@mui/material';
import WalletConnect from 'components/WalletConnect';
import HomeButton from 'components/buttons/HomeButton';
import NFTCollectionSection from './NFTPage/NFTCollectionSection';
import NFTCollectionEliteSection from './NFTPage/NFTCollectionEliteSection';
import NFTGallery from './NFTPage/NFTGallerySection';
import LandingPageBottomAppbar from '../components/layout/LandingPageLayout/LandingPageBottomAppbar';

function NFTPage() {
  if (
    (performance.getEntriesByType('navigation')[0] as any).type === 'reload'
  ) {
    window.location.href = '/nft-sale';
    console.info('This page is reloaded');
  }

  return (
    <>
      <WalletConnect />
      <HomeButton page='landing-pages' />
      <Stack direction='column'>
        <NFTGallery />
        <NFTCollectionSection />
        <NFTCollectionEliteSection />
        <LandingPageBottomAppbar />
      </Stack>
    </>
  );
}

export default NFTPage;
