import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import logo1 from '../../assets/press-kit/Fame_Dropshadow_Logo_MASTER_RGB.png';
import logo2 from '../../assets/press-kit/Fame_Logo_RBG_Black.png';
import logo3 from '../../assets/press-kit/Fame_Logo_RBG_WHITE.png';
import logo1SVG from '../../assets/press-kit/Fame_Dropshadow_Logo_MASTER_RGB.svg';
import logo2SVG from '../../assets/press-kit/Fame_Logo_RGB_BLACK.svg';
import logo3SVG from '../../assets/press-kit/Fame_Logo_RGB_WHITE.svg';
import FameLogoDownload from '../../components/press-kit/FameLogoDownload';
import LandingPageBottomAppbar from '../../components/layout/LandingPageLayout/LandingPageBottomAppbar';

function PressKitPage() {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Box
      top={matches ? '32vh' : '45vh'}
      minHeight='50px'
      position='absolute'
      right='0px'
      left='0px'
      zIndex={-1}
    >
      <Stack direction='column' alignItems='center' justifyContent='center'>
        <Stack
          spacing={1}
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: matches ? '50%' : '80%',
            paddingTop: matches ? '30px' : '0px'
          }}
        >
          <Typography
            color='white'
            fontSize={matches ? '18px' : '12px'}
            fontWeight={600}
            sx={{ textShadow: '2px 2px 2px black', paddingBottom: '20px' }}
            align='center'
          >
            Please do not edit, modify, distort, recolor, or reconfigure the
            Fame logo.
          </Typography>
          <Grid
            container
            columns={{ xs: 12, md: 12 }}
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={12} md={4}>
              <FameLogoDownload
                className='logo-container'
                altText='fame-logo-1'
                fileNameSVG='logo-drop-shadow.svg'
                fileNamePNG='logo-drop-shadow.png'
                imageFilePNG={logo1}
                imageFileSVG={logo1SVG}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FameLogoDownload
                className='logo-container-white'
                altText='fame-logo-2'
                fileNameSVG='logo-black.svg'
                fileNamePNG='logo-black.png'
                imageFilePNG={logo2}
                imageFileSVG={logo2SVG}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FameLogoDownload
                className='logo-container'
                altText='fame-logo-3'
                fileNameSVG='logo-white.svg'
                fileNamePNG='logo-white.png'
                imageFilePNG={logo3}
                imageFileSVG={logo3SVG}
              />
            </Grid>
          </Grid>
          <Typography
            color='white'
            fontSize={matches ? '18px' : '16px'}
            fontWeight={600}
            justifyContent='center'
            textAlign='center'
            sx={{
              textShadow: '2px 2px 2px black',
              paddingTop: '30px',
              paddingBottom: '10px'
            }}
          >
            Fame Colors
          </Typography>
          <Grid
            container
            spacing={2}
            columns={{ xs: 12, md: 12 }}
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={12} md={4}>
              <TextareaAutosize
                className='color-textarea'
                aria-label='textarea'
                maxRows={3}
                defaultValue='RED &#13;&#10;#FF0000 &#13;&#10;255, 0, 0'
                style={{
                  backgroundColor: '#FF0000',
                  color: 'white',
                  marginBottom: matches ? '0px' : '20px'
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextareaAutosize
                className='color-textarea'
                aria-label='textarea'
                maxRows={3}
                defaultValue='WHITE &#13;&#10;#FFFFFF &#13;&#10;255, 255, 255'
                style={{
                  backgroundColor: '#FFFFFF',
                  marginBottom: matches ? '0px' : '20px'
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextareaAutosize
                className='color-textarea'
                aria-label='textarea'
                maxRows={3}
                defaultValue='BLACK &#13;&#10;#000000 &#13;&#10;0, 0, 0'
                style={{
                  backgroundColor: '#000000',
                  color: 'white',
                  marginBottom: matches ? '0px' : '20px'
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <LandingPageBottomAppbar />
    </Box>
  );
}

export default PressKitPage;
