import React from 'react';
import Button from '@mui/material/Button';

function FameGlobalButton(props: {
  title: string;
  color: string;
  hoverColor: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return (
    <Button
      variant='contained'
      size='medium'
      onClick={props.onClick}
      sx={{
        backgroundColor: props.color,
        textTransform: 'none',
        border: '1px solid white',
        borderRadius: '15px',
        fontSize: '12px',
        '&:hover': {
          backgroundColor: props.hoverColor,
          color: props.hoverColor === 'white' ? 'red' : 'white',
          border: `1px solid ${props.hoverColor === 'white' ? 'red' : 'white'}`
        }
      }}
      type="submit"
    >
      {props.title}
    </Button>
  );
}

export default FameGlobalButton;
