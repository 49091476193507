import React, { useState, useEffect, useRef, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';
import CurrentUserContext from '../../hooks/currentUserContext';

function ContactFame({ isOpen, handleClose }: any) {
  const form = useRef();

  const { setCurrentUser }: any = useContext(CurrentUserContext);

  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event: any) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const [hasError, setHasError] = useState(false)

  const handleEmailChange = (event: any) => {

    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if ( re.test(event.target.value) ) {
      setInputValues({ ...inputValues, [event.target.name]: event.target.value });
      setHasError(false)
    }
    else {
      setHasError(true)
    }
  };

  const sendEmail = (event: any) => {
    event.preventDefault();
    handleClose()
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID!,
          process.env.REACT_APP_TEMPLATE_ID!,
          form.current!,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          () => {
            setInputValues({
              name: '',
              email: '',
              message: ''
            });
            setCurrentUser({
              emailSent: true
            })
          },
          (error) => {
            console.error('FAILED...', error);
          }
        );
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '20px',
            backgroundColor: 'white',
            border: 'solid 3px red'
          }
        }}
      >
        <DialogTitle
          id='responsive-dialog-title'
          sx={{ m: 0, p: 2, backgroundColor: 'red' }}
        >
          <Typography
            align='center'
            fontWeight={600}
            fontSize={18}
            color='white'
          >
            Contact Fame
          </Typography>
          {handleClose ? (
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Box
          sx={{
            padding: '20px'
          }}
        >
          <DialogContent>
            <form ref={form as any} onSubmit={sendEmail}>
              <Stack direction='column'>
                <TextField
                  name='name'
                  placeholder='Full Name'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountBoxIcon />
                      </InputAdornment>
                    )
                  }}
                  sx={{ paddingBottom: '15px' }}
                  onChange={handleChange}
                  required
                />
                <TextField
                  error={hasError}
                  name='email'
                  placeholder='Email'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                  sx={{ paddingBottom: '15px' }}
                  helperText={hasError ? "Please use a valid email address" : ""}
                  onChange={handleEmailChange}
                  required
                />
                <TextField
                  name='message'
                  placeholder='Message'
                  multiline
                  rows={4}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position='start'
                        sx={{
                          display: 'flex',
                          flexDirection: 'column-reverse'
                        }}
                      >
                        <MessageIcon />
                      </InputAdornment>
                    )
                  }}
                  sx={{ alignItems: 'flex-start', paddingBottom: '5px' }}
                  onChange={
                    handleChange
                  }
                  required
                />
                <Button
                  size='large'
                  variant='contained'
                  sx={{
                    marginTop: '10px',
                    bgcolor: '#FF0000',
                    borderRadius: 5,
                    textTransform: 'none',
                    ':hover': {
                      bgcolor: '#FF0000'
                    }
                  }}
                  type='submit' // to add onclick
                >
                  Send Message
                </Button>
              </Stack>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

export default ContactFame;
