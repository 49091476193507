import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Paper } from '@mui/material';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  OperaWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import HomeButton from 'components/buttons/HomeButton';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';
import { AuthRedirectWrapper } from 'components/AuthRedirectWrapper';
import mobileBG from '../../assets/demo/images/mobileBG.png';
import desktopBG from '../../assets/demo/images/desktopBG.png';
import fameLogo from '../../assets/demo/images/logo.png';
import './index.scss';

const UnlockPage = () => {
  const commonProps = {
    callbackRoute: routeNames.nftSale,
    nativeAuth: true // optional
  };
  const matches = useMediaQuery('(min-width:600px)');
  const bg = matches ? `url(${desktopBG})` : `url(${mobileBG})`;
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: matches
          ? `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%), ${bg}`
          : `linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0)  80%), ${bg}`,
        backgroundSize: 'cover',
        borderRadius: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '50px'
      }}
      elevation={3}
    >
      <HomeButton page='' />
      <Stack>
        <img
          onClick={() => {
            navigate('/');
          }}
          alt='fame-logo'
          src={fameLogo}
          style={{
            width: '90%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: matches ? '200px' : '200px'
          }}
        />
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            backgroundColor: 'red',
            paddingX: '40px',
            paddingTop: '20px',
            borderRadius: '20px',
            border: '2px red solid',
            margin: '20px'
          }}
        >
          <Typography color='white' fontSize='24px' fontWeight={600}>
            Connect your wallet
          </Typography>
          <Typography color='white' fontSize='14px'>
            Choose one of the options below
          </Typography>
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
              backgroundColor: 'white',
              padding: '40px',
              width: '100%',
              marginTop: '20px',
              bottom: 0
            }}
          >
            <ExtensionLoginButton
              className='login-btns unlock'
              loginButtonText='Extension'
              {...commonProps}
            />
            <OperaWalletLoginButton
              className='login-btns'
              loginButtonText='Opera Crypto Wallet - Beta'
              {...commonProps}
            />
            <WebWalletLoginButton
              className='login-btns'
              loginButtonText='Web wallet'
              {...commonProps}
            />
            <LedgerLoginButton
              className='login-btns'
              loginButtonText='Ledger'
              {...commonProps}
            />
            <WalletConnectLoginButton
              className='login-btns'
              loginButtonText='Maiar'
              {...commonProps}
              {...(walletConnectV2ProjectId
                ? {
                    isWalletConnectV2: true
                  }
                : {})}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
    // <div className='home d-flex flex-fill align-items-center'>
    //   <div className='m-auto' data-testid='unlockPage'>
    //     <div className='card my-4 text-center'>
    //       <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>
    //         <h4 className='mb-4'>Login</h4>
    //         <p className='mb-4'>pick a login method</p>
    // <ExtensionLoginButton
    //   loginButtonText='Extension'
    //   {...commonProps}
    // />

    // <OperaWalletLoginButton
    //   loginButtonText='Opera Crypto Wallet - Beta'
    //   {...commonProps}
    // />

    // <WebWalletLoginButton
    //   loginButtonText='Web wallet'
    //   {...commonProps}
    // />
    // <LedgerLoginButton
    //   loginButtonText='Ledger'
    //   className='test-class_name'
    //   {...commonProps}
    // />
    // <WalletConnectLoginButton
    //   loginButtonText='Maiar'
    //   {...commonProps}
    //   {...(walletConnectV2ProjectId
    //     ? {
    //         isWalletConnectV2: true
    //       }
    //     : {})}
    // />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export const Unlock = () => (
  <AuthRedirectWrapper>
    <UnlockPage />
  </AuthRedirectWrapper>
);
