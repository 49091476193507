import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { collections } from 'data';
import './index.scss';

interface IProps {
    index: number
    mintedEliteNftCount: number;
    mintedRegularNftCount: number;
    handleEliteMint: (collectionIndex: number, amount: number) => Promise<void>;
    handleRegularMint: (collectionIndex: number, amount: number) => Promise<void>;
}

const totalEliteCount = 35;
const totalRegularCount = 200;

const AdminNftMintCard = (props: IProps) => {
    const { index, mintedEliteNftCount, mintedRegularNftCount, handleEliteMint, handleRegularMint } = props;
    const [eliteNftAmount, setEliteNftAmount] = useState<number>(0);
    const [regularNftAmount, setRegularNftAmount] = useState<number>(0);

    const handleEliteNft = async () => {
        if (eliteNftAmount <= 0) {
            return toast.warn("Invalid amount");
        }

        if (eliteNftAmount > totalEliteCount - mintedEliteNftCount) {
            return toast.warn("The number of Elite NFTs available for admin to mint is over");
        }

        await handleEliteMint(index + 1, eliteNftAmount);
    }

    const handleRegularNft = async () => {
        if (regularNftAmount <= 0) {
            return toast.warn("Invalid amount");
        }

        if (regularNftAmount > 60) {
            return toast.warn("Max limit is 60 per mint");
        }

        if (regularNftAmount > totalRegularCount - mintedRegularNftCount) {
            return toast.warn("The number of regular NFTs available for admin to mint is over");
        }

        await handleRegularMint(index + 1, regularNftAmount);
    }

    return (
        <div className='admin-nft-mint-card'>
            <h3>{collections[index].collectionName}</h3>
            <div className='admin-nft-mint-card-wrap'>
                <div className=''>
                    <div>{`${mintedEliteNftCount}/${totalEliteCount}`}</div>
                    <div className='nft-mint-container'>
                        <input
                            type={'number'}
                            value={eliteNftAmount}
                            disabled={mintedEliteNftCount >= totalEliteCount}
                            onChange={e => setEliteNftAmount(Number(e.target.value))}
                        />
                        <button
                            onClick={handleEliteNft}
                            disabled={mintedEliteNftCount >= totalEliteCount}
                        >
                            Elite NFT Mint
                        </button>
                    </div>
                </div>

                <div className=''>
                    <div>{`${mintedRegularNftCount}/${totalRegularCount}`}</div>
                    <div className='nft-mint-container'>
                        <input
                            type={'number'}
                            value={regularNftAmount}
                            disabled={mintedRegularNftCount >= totalRegularCount}
                            onChange={e => setRegularNftAmount(Number(e.target.value))}
                        />
                        <button
                            onClick={handleRegularNft}
                            disabled={mintedRegularNftCount >= totalRegularCount}
                        >
                            Regular NFT Mint
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNftMintCard;